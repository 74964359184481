<template>
  <el-form ref="fieldsForm"

           :model="form" :rules="rules" label-width="120px">
    <el-form-item prop="name" label="Field Name">
      <el-input v-model="form.name"></el-input>
    </el-form-item>

    <el-form-item label="Help Text">
      <vue-editor v-model="form.helpText" />
    </el-form-item>

    <el-form-item prop="type" label="Type">
      <el-select v-model="form.type" placeholder="Please select one">
        <!-- <el-option label="Event Password" value="event_password"></el-option> -->
        <el-option label="Short Text" value="short_text"></el-option>
        <el-option label="Long Text" value="long_text"></el-option>
        <el-option label="Checkbox" value="checkbox"></el-option>
        <el-option label="Select Box" value="select_box"></el-option>
        <el-option label="HTML Content" value="html_content"></el-option>
        <el-option label="Email" value="email"></el-option>
      </el-select>
    </el-form-item>
    <el-form-item v-if="form.type == 'select_box'" prop="options" label="">

    <el-row>
      <el-col :span="24">
        <b>Select Box Options</b>
      </el-col>
      <el-col :span="24">
        <!--
        <el-tag
            :key="tag"
            v-for="tag in dynamicOptions"
            closable
            :disable-transitions="false"
            @close="handleClose(tag)">
          {{tag}}
        </el-tag>
        -->
        <!-- class="input-new-tag" -->
        <el-input
            v-model="inputValue"
            ref="saveTagInput"
            size="mini"
            @keyup.enter.native="handleInputConfirm"
            @blur="handleInputConfirm"
        >
        </el-input>
        <!--
        <el-button class="button-new-tag ml-2" size="small"
                   @click="showInput">+ New Option</el-button>
                   -->
      </el-col>

      <el-col :span="24">
        <el-checkbox-group v-model="otherOption">
          <el-checkbox label="Add Other Option" name="otherOption">
            <span slot="label">Add <b>Other</b> Option</span>
          </el-checkbox>
        </el-checkbox-group>
      </el-col>
    </el-row>
  </el-form-item>
    <el-form-item v-if="form.type == 'html_content'" prop="options" label="">
      <vue-editor v-model="htmlContent" />
    </el-form-item>
    <el-form-item label="">
      <el-checkbox-group v-model="form.required">
        <el-checkbox label="Required" name="type"></el-checkbox>
      </el-checkbox-group>
    </el-form-item>

    <el-form-item>
      <el-button type="primary" @click="onSubmit">Save</el-button>
      <el-button @click="$emit('close')">Close</el-button>
    </el-form-item>
  </el-form>
</template>

<style>
  .el-tag + .el-tag {
    margin-left: 10px;
  }
  .button-new-tag {
    margin-left: 10px;
    height: 32px;
    line-height: 30px;
    padding-top: 0;
    padding-bottom: 0;
  }
  .input-new-tag {
    width: 90px!important;
    max-width: 90px!important;
    margin-left: 10px;
    vertical-align: bottom;
  }
</style>

<script>
import { VueEditor } from 'vue2-editor';

export default {
  name: 'Edit',
  components: { VueEditor },
  props: ['field'],
  data() {
    return {
      htmlContent: '',
      otherOption: false,
      dynamicOptions: [],
      inputVisible: false,
      inputValue: '',
      form: {
        name: '',
        required: false,
        type: null,
        is_shown: false,
      },
      rules: {
        name: [
          { required: true, message: 'Please input Field Name', trigger: 'blur' },
          {
            min: 3,
            max: 40,
            message: 'Length should be 3 to 40',
            trigger: 'blur',
          },
        ],
        type: [
          { required: true, message: 'Please select a Type', trigger: 'change' },
        ],
      },
    };
  },
  watch: {
    field(val) {
      this.form = val;
      if (val.options) {
        this.inputValue = val.options.join(',');
      }
    },
  },
  mounted() {},
  methods: {
    formClear() {
      this.form = {
        name: '',
        required: false,
        type: null,
        is_shown: false,
      };
      this.htmlContent = '';
    },
    openAsEdit() {
      this.form = this.field;
      if (this.field.options) {
        this.inputValue = this.field.options.join(',');
      }
      if (this.field.type === 'html_content') {
        this.htmlContent = this.field.content;
      }
    },
    handleClose(tag) {
      this.dynamicOptions.splice(this.dynamicOptions.indexOf(tag), 1);
    },

    showInput() {
      this.inputVisible = true;
      this.$nextTick(() => {
        this.$refs.saveTagInput.$refs.input.focus();
      });
    },

    handleInputConfirm() {
      if (this.inputValue) {
        this.dynamicOptions = this.inputValue.split(',');
      }
    },
    onSubmit() {
      const self = this;

      this.$refs.fieldsForm.validate((valid) => {
        if (valid) {
          if (this.form.type === 'select_box') {
            if (this.otherOption) {
              this.dynamicOptions.push('Other');
            }
            this.form.options = this.dynamicOptions;
          } else if (this.form.type === 'html_content') {
            this.form.content = this.htmlContent;
          }
          self.$emit('save', this.form);
          this.inputValue = '';
        } else {
          console.log('error submit!!');
        }
      });
    },
  },
};
</script>

<style scoped>

</style>
