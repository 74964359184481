<template>
  <el-tabs v-model="activeTab">
    <el-tab-pane label="Automated" name="automated">
      <RegistrationConfirmation />
    </el-tab-pane>
    <el-tab-pane label="EmailUpdate" name="update">
      <EmailUpdate />
    </el-tab-pane>
  </el-tabs>
</template>

<script>
import EmailUpdate from '@/views/event/settings/emails/EmailUpdate.vue';
import RegistrationConfirmation from '@/views/event/settings/emails/RegistrationConfirmation.vue';

export default {
  data() {
    return {
      activeTab: 'automated',
    };
  },
  components: {
    RegistrationConfirmation,
    EmailUpdate,
  },
};
</script>
