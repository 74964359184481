import Vue from 'vue';
import VueRouter from 'vue-router';

import { auth } from '@/firebase';

import store from '@/store';
// import _ from 'lodash';
import Login from '../views/Login.vue';
import AccountBase from '../views/account/AccountBase.vue';
import SelectAccount from '../views/account/SelectAccount.vue';
import EventList from '../views/event/EventList.vue';
import EventSettingsBase from '../views/event/EventSettingsBase.vue';
import EventOverview from '../views/event/EventOverview.vue';
import WhoIsOnline from '../views/event/reports/WhoIsOnline.vue';
import Registrations from '../views/event/reports/Registrations.vue';
import ChatMessages from '../views/event/reports/ChatMessages.vue';
import InfoAndSettings from '../views/event/settings/InfoAndSettings.vue';
import SecuritySettings from '../views/event/settings/SecuritySettings.vue';
import PageSettings from '../views/event/settings/PageSettings.vue';
import ShortLink from '../views/ShortLink.vue';
import Fields from '../views/event/registrationForms/Fields.vue';
import StreamSettings from '../views/event/settings/StreamSettings.vue';
import LandingPageSettings from '../views/event/settings/LandingPageSettings.vue';
import Emails from '../views/event/settings/Emails.vue';
import Attendees from '../views/event/reports/Attendees.vue';
import Account from '../views/Account.vue';

import UserList from '../views/user/UserList.vue';
import UserForm from '../views/user/UserForm.vue';
import EditAccount from '../views/account/EditAccount.vue';
// import Home from '../views/Home.vue';

Vue.use(VueRouter);

const checkLogin = (to, from, next) => {
  const user = auth.currentUser;
  if (!user) {
    next('/login');
  } else {
    next();
  }
};

const routes = [
  {
    path: '/',
    name: 'Home',
    component: EventList,
    beforeEnter: checkLogin,
  },
  {
    path: '/accounts',
    component: Account,
    beforeEnter: checkLogin,
  },
  {
    path: '/account/',
    component: AccountBase,
    children: [
      {
        // UserProfile will be rendered inside User's <router-view>
        // when /user/:id/profile is matched
        name: 'selectAccount',
        path: 'select',
        component: SelectAccount,
        beforeEnter: checkLogin,
      },
      {
        name: 'edit',
        path: 'edit/:id',
        component: EditAccount,
        beforeEnter: checkLogin,
      },
    ],
  },
  {
    path: '/event/:id',
    component: EventSettingsBase,
    children: [
      {
        // UserProfile will be rendered inside User's <router-view>
        // when /user/:id/profile is matched
        name: 'overview',
        path: 'overview',
        component: EventOverview,
        beforeEnter: checkLogin,
      },
      {
        name: 'whoisonline',
        path: 'whoisonline',
        component: WhoIsOnline,
        beforeEnter: checkLogin,
      },
      {
        name: 'registrations',
        path: 'registrations',
        component: Registrations,
        beforeEnter: checkLogin,
      },
      {
        name: 'chatmessages',
        path: 'chatMessages',
        component: ChatMessages,
        beforeEnter: checkLogin,
      },
      {
        name: 'infoandsettings',
        path: 'infoandsettings',
        component: InfoAndSettings,
        beforeEnter: checkLogin,
      },
      {
        name: 'securitysettings',
        path: 'securitysettings',
        component: SecuritySettings,
        beforeEnter: checkLogin,
      },
      {
        name: 'pagesettings',
        path: 'pagesettings',
        component: PageSettings,
        beforeEnter: checkLogin,
      },
      {
        name: 'registrationForm',
        path: 'registrationForm',
        component: Fields,
        beforeEnter: checkLogin,
      },
      {
        name: 'streamsettings',
        path: 'streamsettings',
        component: StreamSettings,
        beforeEnter: checkLogin,
      },
      {
        name: 'landingpage',
        path: 'landingpage',
        component: LandingPageSettings,
        beforeEnter: checkLogin,
      },
      {
        name: 'emails',
        path: 'emails',
        component: Emails,
        beforeEnter: checkLogin,
      },
      {
        name: 'attendees',
        path: 'attendees',
        component: Attendees,
        beforeEnter: checkLogin,
      },
    ],
  },
  {
    path: '/login',
    name: 'login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: Login,
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      // eslint-disable-next-line implicit-arrow-linebreak
      import(/* webpackChunkName: "about" */ '../views/About.vue'),
  },
  {
    path: '/shortlink',
    name: 'Shortlink',
    component: ShortLink,
    beforeEnter: checkLogin,
  },
  {
    path: '/user/new',
    name: 'user',
    component: UserForm,
  },
  {
    path: '/user/list',
    name: 'userList',
    component: UserList,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  await store.dispatch('account/init');
  if (store.state.account && store.state.account.current) {
    next();
  } else if (to.name !== 'selectAccount' && to.name !== 'login') {
    next(false);
    next('/account/select');
  } else {
    console.log('next: ', to.name);
    next();
  }
});

export default router;
