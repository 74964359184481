<template>
  <el-card shadow="never" class="field-card">
    <el-row>
      <el-col :span="2">
        <el-checkbox
          :disabled="field.is_mandatory"
          v-model="field.is_shown"
        ></el-checkbox>
      </el-col>
      <el-col style="text-align: left" :span="18">
        {{ field.name }}
      </el-col>
      <el-col :span="2">
        <el-checkbox
            :disabled="field.type === 'name' || field.type === 'email'"
            v-model="field.required"></el-checkbox>
      </el-col>
      <el-col v-if="!field.is_default" :span="2">
        <el-button @click="$emit('onEdit')" size="mini" type="info"
          >Edit</el-button
        >
      </el-col>
    </el-row>
  </el-card>
</template>

<script>
export default {
  name: 'FieldItem',
  props: {
    field: {
      type: Object,
      default: null,
    },
  },
};
</script>

<style scoped>
</style>
