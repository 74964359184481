/* eslint-disable object-curly-newline */
/* eslint-disable arrow-parens */
/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */

import _ from 'lodash';
import * as fb from '../../firebase';

const connections = {
  namespaced: true,

  state: {
    count: 0,
  },

  mutations: {
    setCount: (state, count) => {
      state.count = count;
    },
  },

  actions: {
    getCount: async ({ rootGetters, commit }) => {
      const {
        'event/current': { id: eventId },
      } = rootGetters;
      const eventRef = await fb.db.doc(`event/${eventId}`).get();
      const event = eventRef.data();

      if (event.currentSession) {
        const onlineRef = fb
          .database()
          .ref(`event/${eventId}/${event.currentSession}`)
          .orderByChild('status')
          .equalTo('playing');

        onlineRef.on('value', (snapshot) => {
          const list = _.values(snapshot.toJSON());
          const onlineList = _.orderBy(list, 'createdAt', 'desc');
          const currentConnectionCount = onlineList.length;

          commit('setCount', currentConnectionCount);
        });
      }
    },
  },

  getters: {
    count: (state) => state.count,
  },
};

export default connections;
