<template>
  <el-row
    :gutter="64"
    type="flex"
    justify="center"
    style="height: 100vh; align-items: center"
  >
    <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="6">
      <el-card class="login-card" shadow="never">
        <div slot="header" class="clearfix">
          <span>Login</span>
        </div>
        <el-alert
          title="Error"
          type="error"
          :description="errorDescription"
          show-icon
          v-if="errorDescription"
        >
        </el-alert>
        <el-form
          ref="loginForm"
          label-position="top"
          :model="form"
          @submit.prevent
        >
          <el-form-item label="Email">
            <el-input
              v-model="form.email"
              type="email"
              :required="true"
              name="email"
              autocomplete="true"
            ></el-input>
          </el-form-item>
          <el-form-item label="Password">
            <el-input
              v-model="form.password"
              show-password
              type="password"
              name="password"
              required
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="login()">Login</el-button>
          </el-form-item>
        </el-form>
      </el-card>
    </el-col>
  </el-row>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  data() {
    return {
      form: {
        email: '',
        password: '',
      },
      errorDescription: null,
    };
  },
  methods: {
    ...mapActions({
      loginAction: 'auth/login',
    }),
    async login() {
      this.errorDescription = null;
      try {
        await this.loginAction(this.form);
        this.$router.push('/');
      } catch (error) {
        console.log(error);
        this.errorDescription = error.message;
      }
    },
  },
};
</script>

<style>
</style>
