import BaseModule from '@/store/baseFireVuex';
import axios from 'axios';
import Schema from 'async-validator';
import { storage, db, timestamp } from '../../firebase';

/* eslint-disable max-classes-per-file */
/* eslint-disable no-param-reassign */
/* eslint-disable no-return-await */

const schema = {
  title: {
    type: 'string',
    required: true,
  },
  startAt: {
    type: 'date',
    required: true,
  },
  endAt: {
    type: 'date',
    required: true,
  },
  interfaceLang: {
    type: 'string',
    required: true,
  },

  shortLink: {
    type: 'string',
    required: false,
    transform(value) {
      if (!value) {
        return undefined;
      }
      return value.trim();
    },
  },
  type: {
    type: 'string',
    required: true,
    default: 'webinar',
  },
  /*
  status: {
    type: 'string',
    required: true,
    default: 'upcoming', upcoming, live, completed
  },
  */

  accountId: {
    type: 'string',
    required: true,
  },
  thumbUrl: {
    type: 'string',
    required: false,
    default: '',
  },
  status: {
    type: 'string',
    required: false,
    default: 'upcoming',
  },
  meetingId: {
    type: 'string',
    required: false,
  },
  customLiveUrl: {
    type: 'string',
    required: false,
  },
};

/* eslint-disable no-unused-vars */
const options = {
  name: 'Event',
  getCollectionPath: (payload) => '/event',
  schema,
};

/* eslint-disable no-unused-vars */
class EventModule extends BaseModule {
  getters() {
    return {
      ...super.getters(),
      landingUrl: (state) => state.landingUrl,
    };
  }

  state() {
    return {
      ...super.state(),
      landingUrl: '',
    };
  }

  mutations() {
    return {
      ...super.mutations(),
      getLandingUrl: (state, payload) => {
        state.landingUrl = payload;
      },
    };
  }

  actions() {
    const getAndSet = async (context, assignId) => {
      console.log('getAndSet event');
      await context.dispatch('get', { id: assignId, listenUpdates: true });
      await context.dispatch('setCurrent', { id: assignId });
    };
    return {
      ...super.actions(),
      upload: (context, payload) => {
        console.log(context.getters);
        const storageRef = storage()
          .ref()
          .child(`/attendees/${payload.file.name}`);

        storageRef.put(payload.file).then((snapshot) => {
          console.log('File successfully uploaded');
        });
      },
      init: async (context, id) => {
        console.log(id);
        if (id) {
          await getAndSet(context, id);
          return context.getters.current;
        }
        if (context.getters.current !== undefined) {
          return context.getters.current;
        }

        return context.getters.current;
      },

      create: async (context, payload) => {
        const { data } = payload;

        context.commit('setLoading', true);

        const validator = new Schema(this.schema);
        await validator.validate(data).catch((error) => {
          console.log(error);
          const e = Object.assign(error, {
            message: `${this.name} validation error`,
          });
          throw e;
        });

        return axios
          .post(`${process.env.VUE_APP_BASE_URL}/event`, data)
          .then((res) => {
            console.log(res);
            const doc = res.data.event;
            context.commit('addItem', doc);
            context.commit('setLoading', false);
            console.log('created');
            return doc;
          });
      },
      // event'a ait bilgileri güncelleme
      editInfo: async ({ getters, commit }, { form }) => {
        try {
          const currentEvent = getters.current;

          // form içinden id field'ını sil
          delete form.id;

          // event'i güncelle ve güncellenmiş kaydı event listesine yansıt
          await db.doc(`event/${currentEvent.id}`).update({
            ...form,
            ...(form.startAt ? { startAt: new Date(form.startAt) } : null),
            ...(form.endAt ? { endAt: new Date(form.endAt) } : null),
            updatedAt: timestamp(),
          });
          const event = (
            await db
              .doc(`event/${currentEvent.id}`)
              .withConverter(this.converter)
              .get()
          ).data();

          commit('addItem', event);

          return true;
        } catch (error) {
          console.log(error);

          return false;
        }
      },
      // security settings güncelleme/kaydetme
      setSecuritySettings: async ({ getters }, { form }) => {
        try {
          const currentEvent = getters.current;
          const secSettingsSnap = await db
            .doc(`event/${currentEvent.id}/settings/securitySettings`)
            .get();

          // eğer securitysettings varsa güncelle, yoksa yeni oluştur
          if (secSettingsSnap.exists) {
            await db
              .doc(`event/${currentEvent.id}/settings/securitySettings`)
              .update({ ...form, updatedAt: timestamp() });

            return true;
          }
          await db
            .doc(`event/${currentEvent.id}/settings/securitySettings`)
            .set({ ...form, createdAt: timestamp() });

          return true;
        } catch (error) {
          console.log(error);
          return false;
        }
      },
      // pageSettings güncelleme/kaydetme
      setPageSettings: async ({ getters }, { form }) => {
        try {
          const currentEvent = getters.current;
          const pageSettingsRef = await db
            .doc(`event/${currentEvent.id}/settings/pageSettings`)
            .get();

          // eğer event'e ait pageSettings halihazırda varsa update et, yoksa yeni oluştur ve kaydet
          if (!pageSettingsRef.exists) {
            await pageSettingsRef.ref.set({
              ...form,
              createdAt: timestamp(),
              eventId: currentEvent.id,
            });
          } else {
            await db
              .doc(`event/${currentEvent.id}/settings/pageSettings`)
              .update({
                ...form,
                updatedAt: timestamp(),
              });
          }

          return true;
        } catch (error) {
          console.log(error);

          return false;
        }
      },
      // landingUrl alma
      getLandingUrl: async ({ commit, getters }) => {
        const {
          current: { accountId, id },
        } = getters;
        // genel appsettings'i ve onun landingUrl değerini al
        const appSettings = (await db.doc('appSettings/general').get()).data();
        let { landingUrl } = appSettings;

        // eğer current event içinde accountId değeri varsa, ilgili account bilgilerini al
        // eğer account'a ait bir landingUrl değeri varsa, onu landingUrl değeri kabul et
        if (accountId) {
          const accountRef = await db.doc(`account/${accountId}`).get();

          if (accountRef) {
            const account = accountRef.data();

            if (account.landingUrl && account.landingUrl !== '') {
              landingUrl = account.landingUrl;
            }
          }
        }

        commit('getLandingUrl', `${landingUrl}/${id}`);
      },
    };
  }
}

export default new EventModule(options).getModule();
/* eslint-enable max-classes-per-file */
