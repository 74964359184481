<template>
  <div class="settings-container">
    <div class="settings-item" v-if="form.thumbUrl">
      <div class="title">Poster Image</div>
      <el-image :src="form.thumbUrl"></el-image>
    </div>
    <div class="settings-item">
      <el-upload
        class="upload-demo"
        ref="upload"
        drag=""
        action=""
        :http-request="submitImg"
        :auto-upload="true"
        :limit="1"
        :thumbnail-mode="true"
        list-type="picture"
      >
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">
          Drop file here or <em>click to upload</em>
        </div>
        <div class="el-upload__tip" slot="tip">
          jpg/png files with a size less than 500kb
        </div>
      </el-upload>
    </div>
    <div class="settings-item">
      <div class="title">Title</div>
      <el-input placeholder="Title" v-model="form.title"></el-input>
    </div>
    <div class="settings-item">
      <div class="title">Start</div>
      <el-date-picker
        v-model="form.startAt"
        type="datetime"
        placeholder="Select date and time"
      >
      </el-date-picker>
    </div>
    <div class="settings-item">
      <div class="title">End</div>
      <el-date-picker
        v-model="form.endAt"
        type="datetime"
        placeholder="Select date and time"
      >
      </el-date-picker>
    </div>
    <div class="settings-item">
      <div class="title">Language</div>
      <el-select v-model="form.interfaceLang" placeholder="Select">
        <el-option
          v-for="item in languages"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
    </div>
    <div class="settings-item">
      <el-button type="primary" v-on:click="submit" :disabled="uploading"
        >Save</el-button
      >
    </div>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import moment from 'moment';
import { mapGetters, mapActions } from 'vuex';
import * as fb from '@/firebase';

export default {
  computed: {
    ...mapGetters({
      currentEvent: 'event/current',
    }),
  },
  data() {
    return {
      posterimg: null,
      languages: [
        { value: 'en', label: 'English' },
        { value: 'de', label: 'Deutsche' },
        { value: 'tr', label: 'Turkish' },
        { value: 'es', label: 'Spanish' },
      ],
      form: {},
      uploading: false,
    };
  },
  async created() {
    this.form = {
      ...this.currentEvent,
      startAt: moment(this.currentEvent.startAt.toDate()),
      endAt: moment(this.currentEvent.endAt.toDate()),
    };
  },
  methods: {
    ...mapActions({
      editInfo: 'event/editInfo',
    }),
    async submitImg(file) {
      try {
        this.uploading = true;
        const storageRef = fb
          .storage()
          .ref()
          .child(`/${this.currentEvent.id}/${file.file.name}`);
        const posterSnapshot = await storageRef.put(file.file);

        this.posterimg = await posterSnapshot.ref.getDownloadURL();
        this.$notify({
          title: 'Success',
          message: 'Upload Successfull',
          type: 'success',
        });
        this.uploading = false;
      } catch (error) {
        console.log(error);
        this.$notify.error({
          title: 'Error',
          message: 'An Error Occurred While Uploading Image',
        });
        this.uploading = false;
      }
    },
    async submit() {
      Object.keys(this.form).forEach(
        (key) => this.form[key] === undefined && delete this.form[key],
      );

      if (this.posterimg) {
        Object.assign(this.form, { thumbUrl: this.posterimg });
      }

      const response = await this.editInfo({
        form: this.form,
        posterImg: this.posterimg,
      });

      if (response) {
        this.$notify({
          title: 'Success',
          message: 'Settings Saved Successfully',
          type: 'success',
        });
      } else {
        this.$notify.error({
          title: 'Error',
          message: 'An Error Occurred While Saving Settings',
        });
      }
    },
  },
};
</script>

<style></style>
