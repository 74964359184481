<template>
  <el-header class="event-header">
    <el-tag :type="type" style="font-weight: bold" effect="dark">
      {{ currentEvent.status.toUpperCase() }}
    </el-tag>
    <span class="title">{{ currentEvent.title }}</span>
  </el-header>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters({
      eventList: 'event/list',
      loading: 'event/loading',
      currentEvent: 'event/current',
    }),
    type() {
      if (this.currentEvent.status === 'live') {
        return 'danger';
      }
      if (this.currentEvent.status === 'completed') {
        return 'info';
      }
      return 'success';
    },
  },
};
</script>

<style lang="scss" scoped>
.event-header {
  z-index: 100;
  border: 1px solid #e6e6e6;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .title {
    justify-self: center;
    margin: auto;
  }
}
</style>
