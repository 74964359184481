import Vue from 'vue';
import Vuex from 'vuex';
import auth from '@/store/modules/user';
import account from '@/store/modules/account';
import event from '@/store/modules/event';
import registration from '@/store/modules/registration';
import shortlink from '@/store/modules/shortlink';
import regForm from '@/store/modules/regForm';
import streams from '@/store/modules/streams';
import sessions from '@/store/modules/sessions';
import attendee from '@/store/modules/attendee';
import connections from '@/store/modules/connections';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    account,
    auth,
    event,
    registration,
    shortlink,
    regForm,
    streams,
    sessions,
    attendee,
    connections,
  },
});
