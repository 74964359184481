<template>
  <div class="settings-container">
    <div class="settings-list">
      <div class="settings-item">
        <div class="label">Logo</div>
        <el-upload
          class="image-uploader"
          ref="upload"
          drag=""
          action=""
          :http-request="(file) => submitImg(file, 'logoImg')"
          :auto-upload="true"
          :limit="1"
          list-type="picture"
          disable-transitions
        >
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">
            Drop file here or <em>click to upload</em>
          </div>
          <div class="el-upload__tip" slot="tip">
            jpg/png files with a size less than 500kb
          </div>
        </el-upload>
      </div>
      <div class="settings-item">
        <div class="label">Background Image</div>
        <el-upload
          class="image-uploader"
          ref="upload"
          drag=""
          action=""
          :http-request="(file) => submitImg(file, 'backgroundImg')"
          :auto-upload="true"
          :limit="1"
          list-type="picture"
          :on-remove="handleRemove"
        >
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">
            Drop file here or <em>click to upload</em>
          </div>
          <div class="el-upload__tip" slot="tip">
            jpg/png files with a size less than 500kb
          </div>
        </el-upload>
      </div>
      <div class="settings-item">
        <div class="label">Background Image Position</div>
        <el-select v-model="form.bgImgPosition" placeholder="Select">
          <el-option
            v-for="item in positions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <div class="settings-item">
        <div class="label">Webcast Background Image</div>
        <el-upload
          class="image-uploader"
          ref="upload"
          drag=""
          action=""
          :http-request="(file) => submitImg(file, 'webcastBackgroundImg')"
          :auto-upload="true"
          :limit="1"
          list-type="picture"
          :on-remove="handleRemove"
        >
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">
            Drop file here or <em>click to upload</em>
          </div>
          <div class="el-upload__tip" slot="tip">
            jpg/png files with a size less than 500kb
          </div>
        </el-upload>
      </div>
      <div class="settings-item">
        <div class="label">Brand Color</div>
        <el-input
          placeholder="Brand Color"
          v-model="form.brandColor"
        ></el-input>
      </div>
      <div class="settings-item">
        <div class="label">Call to Action Button</div>
        <el-switch v-model="form.callToActionEnabled"> </el-switch>

        <div class="settings-sub-el" v-if="form.callToActionEnabled">
          <el-input
            v-model="form.callToActionText"
            placeholder="Call to action text"
          ></el-input>
          <el-input
            v-model="form.callToActionLink"
            placeholder="Call to action link"
          ></el-input>
        </div>
      </div>
      <div class="footer clearfix">
        <el-button
          style="float: right"
          type="primary"
          v-on:click="submit"
          :disabled="uploading"
          >Save</el-button
        >
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable indent */

import { mapGetters, mapActions } from 'vuex';
import * as fb from '../../../firebase';

export default {
  computed: {
    ...mapGetters({
      currentEvent: 'event/current',
    }),
  },
  data() {
    return {
      logoImg: null,
      backgroundImg: null,
      webcastBackgroundImg: null,
      form: {},
      testAction: false,
      positions: [
        { value: 'Center', label: 'Center' },
        { value: 'Left', label: 'Left' },
      ],
      uploading: false,
      currentBgImgUrl: null,
      bgFileList: [],
      logoFileList: [],
    };
  },
  async created() {
    const pageSettingsSnap = await fb.db
      .doc(`event/${this.currentEvent.id}/settings/pageSettings`)
      .get();
    const pageSettings = pageSettingsSnap.data();

    // this.bgFileList = pageSettings.bgImgUrl
    //   ? [
    //       {
    //         url: pageSettings.bgImgUrl,
    //       },
    //     ]
    //   : [];
    // this.logoFileList = pageSettings.logoUrl
    //   ? [
    //       {
    //         url: pageSettings.logoUrl,
    //       },
    //     ]
    //   : [];

    this.form = { ...pageSettings };
  },
  methods: {
    ...mapActions({
      setPageSettings: 'event/setPageSettings',
    }),
    async submitImg(file, type) {
      try {
        this.uploading = true;

        const fileName = file.file.name;
        const storageRef = fb
          .storage()
          .ref()
          .child(`/${this.currentEvent.id}/${fileName}`);
        const snapshot = await storageRef.put(file.file);
        const url = await snapshot.ref.getDownloadURL();

        this[type] = url;

        this.$notify({
          title: 'Success',
          message: 'Upload Successfull',
          type: 'success',
        });

        this.uploading = false;
      } catch (error) {
        this.$notify.error({
          title: 'Error',
          message: 'An Error Occurred While Uploading Image',
        });
        this.uploading = false;
      }
    },
    async submit() {
      Object.keys(this.form).forEach(
        (key) => this.form[key] === undefined && delete this.form[key],
      );

      Object.assign(this.form, {
        ...(this.logoImg ? { logoUrl: this.logoImg } : null),
        ...(this.backgroundImg ? { bgImgUrl: this.backgroundImg } : null),
        ...(this.webcastBackgroundImg
          ? { webcastBgImgUrl: this.webcastBackgroundImg }
          : null),
      });

      const saveResponse = await this.setPageSettings({
        form: this.form,
      });

      if (saveResponse) {
        this.$notify({
          title: 'Success',
          message: 'Settings Saved Successfully',
          type: 'success',
        });
      } else {
        this.$notify.error({
          title: 'Error',
          message: 'An Error Occurred While Saving Settings',
        });
      }
    },
    handleRemove(e) {
      console.log(e);
    },
  },
};
</script>

<style></style>
