<template>
  <el-card
    class="box-card"
    shadow="never"
    header="Select Account"
    v-loading="pageLoading"
  >
    <el-input
      placeholder="Please input"
      v-model="search"
      :clearable="true"
      class="mb-4"
      @input="filterList"
    ></el-input>
    <el-button
      class="btn-account"
      type="info"
      plain
      v-for="item in list"
      :key="item.id"
      @click="selectAccount(item.id)"
      >{{ item.name }}</el-button
    >
  </el-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import _ from 'lodash';

export default {
  data() {
    return {
      search: '',
      list: [],
      pageLoading: false,
    };
  },
  computed: {
    ...mapGetters({
      getAccountList: 'account/list',
      loading: 'event/loading',
      schema: 'event/schema',
    }),
  },
  methods: {
    ...mapActions({
      initAccount: 'account/init',
      accountListAction: 'account/find',
      clearState: 'account/clearItems',
      filterAccounts: 'account/filterAccounts',
    }),
    filterList() {
      if (this.search) {
        this.list = _.filter(this.list, (o) => {
          console.log('');
          return _.startsWith(o.name.toUpperCase(), this.search.toUpperCase());
        });
      } else {
        this.list = this.getAccountList;
      }
    },
    async selectAccount(id) {
      await this.initAccount(id);
      this.$router.push('/');
    },
  },
  async created() {
    try {
      this.pageLoading = true;

      // kullanıcının erişimi olan account listesini filtrele
      await this.filterAccounts();

      this.list = this.getAccountList;
      this.pageLoading = false;
    } catch (error) {
      console.log(error);

      this.$alert(error.message, 'Error!', {
        confirmButtonText: 'OK',
        type: 'error',
      });

      this.pageLoading = false;
    }
  },
};
</script>

<style lang="scss" scoped>
.box-card {
  width: 100%;
  max-width: 480px;
  margin: auto;
  .btn-account {
    width: 100%;
    text-align: left;
    margin: 0;
    margin-bottom: 8px;
  }
}
</style>
