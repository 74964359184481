import Vue from 'vue';
import ElementUI from 'element-ui';

import locale from 'element-ui/lib/locale/lang/en';
import VueClipboard from 'vue-clipboard2';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';

import 'element-theme-chalk';
import './scss/index.scss';
import store from './store';
import { auth } from './firebase';

Vue.config.productionTip = false;

Vue.use(VueClipboard);

Vue.use(ElementUI, { locale });
Vue.use(require('vue-moment'));

let app;
auth.onAuthStateChanged(() => {
  if (!app) {
    app = new Vue({
      router,
      store,
      render: (h) => h(App),
    }).$mount('#app');
  }
});
