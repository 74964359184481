<template>
  <el-header class="main-header">
    <div>
      <el-menu
        :default-active="selectedIndex"
        class="el-menu-demo"
        mode="horizontal"
        @select="handleSelect"
      >
        <el-menu-item index="1">Events</el-menu-item>
        <el-menu-item index="2" v-if="isAdmin">Shortlink</el-menu-item>
        <el-menu-item index="3" v-if="isAdmin">Accounts</el-menu-item>
      </el-menu>
    </div>
    <div>
      <el-button v-if="isAdmin" round @click="addUser()">Add User</el-button>
      <el-button
        round
        @click="$router.push('/account/select')"
        icon="el-icon-office-building"
        >{{ currentAccount.name }}</el-button
      >
      <el-button @click="handleLogout()">Logout</el-button>
    </div>
  </el-header>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import _ from 'lodash';

export default {
  name: 'Header',
  components: {},
  data() {
    return {
      routeMap: {
        1: '/',
        2: '/shortlink',
        3: '/accounts',
      },
      selectedIndex: 1,
    };
  },
  computed: {
    ...mapGetters({
      currentEvent: 'event/current',
      currentAccount: 'account/current',
      isAdmin: 'auth/isAdmin',
    }),
  },
  methods: {
    ...mapActions({
      logout: 'auth/logout',
      checkIsAdmin: 'auth/checkIsAdmin',
    }),
    handleSelect(key) {
      this.$router.push(this.routeMap[key]);
    },
    async handleLogout() {
      try {
        await this.logout();
        this.$router.push({ name: 'login' });
      } catch (error) {
        console.log(error);

        this.$notify.error({
          title: 'Error',
          message: 'An Error Occurred While Logging Out',
        });
      }
    },
    addUser() {
      // add user'a tıklandığında yönlendir
      this.$router.push({ path: '/user/new' });
    },
  },
  async created() {
    this.selectedIndex = _.findKey(
      this.routeMap,
      (o) => o === this.$route.path,
    );

    // currentUser'ın admin olup olmadığını kontrol et
    // eğer kullanıcı admin'se add user butonunu göster
    await this.checkIsAdmin();
  },
};
</script>

<style lang="scss" scoped>
.main-header {
  z-index: 100;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.05);
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
