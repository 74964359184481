<template>
  <div :key="pageInd" class="settings-container">
    <el-card shadow="never" style="margin-bottom: 40px">
      <div slot="header" class="clearfix">
        <span>Add New Stream</span>
      </div>
      <el-input placeholder="Stream Name" v-model="streamName">
        <el-button slot="append" v-on:click="add">Add</el-button>
      </el-input>
    </el-card>

    <el-card
      shadow="never"
      v-for="(stream, index) in streams"
      :key="stream._id"
      style="margin-bottom: 2rem"
    >
      <div slot="header" class="clearfix">
        <span>Stream #{{ index }}</span>
      </div>
      <div class="settings-list">
        <div class="settings-item">
          <div class="label">Stream Name</div>
          <el-input placeholder="Please input" v-model="stream.name">
          </el-input>
        </div>
        <div class="settings-item">
          <div>
            <span style="font-weight: bold">Server URL: </span>
            <span>{{ streamSettings.originServerUrl }}</span>
          </div>
          <div>
            <span style="font-weight: bold">Stream ID: </span>
            <span>{{ stream.streamKey }}</span>
          </div>
        </div>

        <div class="settings-item">
          <div class="label">Stream URL</div>
          <el-input placeholder="Stream URL" v-model="stream.playerUrl">
          </el-input>
        </div>
        <div class="footer clearfix" style="text-align: center;">
          <el-button type="primary" v-on:click="edit(index)">Save</el-button>
          <el-button
            style="margin-left: 1rem"
            type="danger"
            v-if="index !== 0"
            v-on:click="triggerDeleteForm(index)"
            >Delete</el-button
          >
        </div>
      </div>
    </el-card>
    <el-dialog title="Delete Stream" :visible.sync="deleteFormVisible">
      <span>Are You Sure?</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="deleteFormVisible = false">Cancel</el-button>
        <el-button type="primary" @click="deleteStream(deleteIndex)"
          >Delete</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
/* eslint-disable import/order */
/* eslint-disable arrow-parens */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-plusplus */

import * as fb from '../../../firebase';
import { mapGetters, mapActions } from 'vuex';

export default {
  data() {
    return {
      streamSettings: {},
      streamName: '',
      pageInd: 0,
      deleteFormVisible: false,
      deleteIndex: null,
    };
  },
  async created() {
    try {
      const streamSettingsSnap = await fb.db
        .collection('appSettings')
        .doc('streamSettings')
        .get();
      const streamSettings = streamSettingsSnap.data();

      Object.assign(this, { streamSettings });
      await this.fetchStreams();
    } catch (error) {
      console.log(error);
    }
  },
  computed: {
    ...mapGetters({
      currentEvent: 'event/current',
      streams: 'streams/currentStreams',
    }),
  },
  methods: {
    ...mapActions({
      fetchStreams: 'streams/fetchCurrentStreams',
      addStreams: 'streams/add',
      editStreams: 'streams/edit',
      deleteStreams: 'streams/delete',
    }),
    async add() {
      const response = await this.addStreams({ name: this.streamName });

      if (response) {
        this.$notify({
          title: 'Success',
          message: 'Stream Added Successfully',
          type: 'success',
        });

        this.pageInd++;
        this.streamName = '';
      } else {
        this.$notify.error({
          title: 'Error',
          message: 'An Error Occurred While Adding Stream',
        });
      }
    },
    async edit(index) {
      const stream = this.streams[index];
      const response = await this.editStreams({ index, stream });

      if (response) {
        this.$notify({
          title: 'Success',
          message: 'Stream Edited Successfully',
          type: 'success',
        });

        this.pageInd++;
      } else {
        this.$notify.error({
          title: 'Error',
          message: 'An Error Occurred While Editing Stream',
        });
      }
    },
    async deleteStream(index) {
      if (index !== 0) {
        const response = await this.deleteStreams({ index });

        if (response) {
          this.$notify({
            title: 'Success',
            message: 'Stream Deleted Successfully',
            type: 'success',
          });

          this.pageInd++;
          this.deleteIndex = null;
          this.deleteFormVisible = false;
        } else {
          this.$notify.error({
            title: 'Error',
            message: 'An Error Occurred While Deleting Stream',
          });
        }
      }
    },
    triggerDeleteForm(index) {
      this.deleteFormVisible = true;
      this.deleteIndex = index;
    },
  },
};
</script>

<style lang="scss"></style>
