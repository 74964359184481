/* eslint-disable no-underscore-dangle */
/* eslint-disable arrow-parens */
/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */

import { v4 as uuidv4 } from 'uuid';

import * as fb from '../../firebase';

const streams = {
  namespaced: true,

  state: {
    currentStreams: [],
  },

  mutations: {
    setCurrentStreams: (state, payload) => {
      state.currentStreams = payload;
    },
    add: ({ currentStreams }, stream) => {
      currentStreams.push(stream);
    },
    edit: (state, { index, stream }) => {
      state.currentStreams[index] = stream;
    },
    delete: (state, payload) => {
      const { currentStreams } = state;

      currentStreams.splice(payload, 1);

      state.list = currentStreams;
    },
  },

  actions: {
    fetchCurrentStreams: async ({ commit, rootGetters }, payload) => {
      try {
        const {
          'event/current': { id: eventId },
        } = rootGetters;
        const streamsSnap = await fb.db
          .collection(`event/${eventId}/stream`)
          .orderBy('createdAt', 'asc')
          .get();
        const streams = [];

        streamsSnap.forEach((snap) => {
          streams.push(snap.data());
        });

        commit('setCurrentStreams', streams);
      } catch (error) {
        console.log(error);
      }
    },
    add: async ({ commit, rootGetters }, { name }) => {
      try {
        const {
          'event/current': { id: eventId },
        } = rootGetters;
        const _id = uuidv4();
        const streamDoc = fb.db.collection(`event/${eventId}/stream`).doc(_id);
        const streamKey = Math.floor(
          100000 + Math.random() * 900000,
        ).toString();
        const streamObj = {};

        Object.assign(streamObj, {
          streamKey,
          name,
          _id,
          eventId,
          status: 'upcoming',
          createdAt: fb.timestamp(),
        });

        await streamDoc.set({
          ...streamObj,
        });

        commit('add', streamObj);

        return true;
      } catch (error) {
        console.log(error);

        return false;
      }
    },
    edit: async ({ commit, rootGetters }, { index, stream }) => {
      try {
        const {
          'event/current': { id: eventId },
        } = rootGetters;

        await fb.db
          .doc(`event/${eventId}/stream/${stream._id}`)
          .update({ ...stream, updatedAt: fb.timestamp() });
        commit('edit', { index, stream });

        return true;
      } catch (error) {
        console.log(error);

        return false;
      }
    },
    delete: async ({ commit, rootGetters, state }, { index }) => {
      try {
        const stream = state.currentStreams[index];
        const {
          'event/current': { id: eventId },
        } = rootGetters;

        await fb.db.doc(`event/${eventId}/stream/${stream._id}`).delete();
        commit('delete', index);
        return true;
      } catch (error) {
        console.log(error);

        return false;
      }
    },
  },

  getters: {
    currentStreams: (state) => state.currentStreams,
  },
};

export default streams;
