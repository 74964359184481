/* eslint-disable object-curly-newline */
/* eslint-disable arrow-parens */
/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */

import * as fb from '../../firebase';

const shortlink = {
  namespaced: true,

  state: {
    list: [],
  },

  mutations: {
    fetchShortLinks: (state, payload) => {
      Object.assign(state, { list: payload });
    },
    editShortLink: (state, { payload, index }) => {
      const { list } = state;

      list[index] = { ...list[index], ...payload };
      state.list = list;
    },
    saveShortLink: (state, payload) => {
      const { list } = state;

      list.push(payload);

      state.list = list;
    },
    delete: (state, payload) => {
      const { list } = state;

      list.splice(payload, 1);

      state.list = list;
    },
  },

  actions: {
    fetchShortLinks: async ({ commit }, payload) => {
      try {
        const shortLinkListSnap = await fb.db.collection('shortLink').get();

        if (!shortLinkListSnap.empty) {
          const shortLinks = [];

          shortLinkListSnap.forEach((snap) => {
            const shortLink = snap.data();

            shortLink.ref = snap.ref;

            shortLinks.push(shortLink);
          });

          commit('fetchShortLinks', shortLinks);
        }
      } catch (error) {
        console.log(error);
      }
    },
    editShortLink: async ({ commit }, { shortlink, to, ref, index }) => {
      try {
        const reg = new RegExp('[ÜĞŞÇÖğıüşöç]');
        const test = reg.test(shortlink);

        if (test) {
          return { val: false, message: 'Turkish characters are not allowed.' };
        }

        await ref.update({ shortlink, to, updatedAt: fb.timestamp() });

        commit('editShortLink', { payload: { shortlink, to }, index });
        return { val: true, message: '' };
      } catch (error) {
        console.log(error);

        return {
          val: false,
          message: 'An Error Occurred While Editing Shortlink',
        };
      }
    },
    saveShortLink: async ({ commit }, { form }) => {
      try {
        const reg = new RegExp('[ÜĞŞÇÖğıüşöç]');
        const test = reg.test(form.shortlink);

        if (test) {
          return { val: false, message: 'Turkish characters are not allowed.' };
        }

        const shortLinkRef = await fb.db
          .collection('shortLink')
          .where('shortlink', '==', form.shortlink)
          .get();

        if (!shortLinkRef.empty) {
          return { val: false, message: 'Shortlink already exists.' };
        }

        const ref = fb.db.collection('shortLink').doc();

        await ref.set({ ...form, createdAt: fb.timestamp() });

        commit('saveShortLink', { ...form, ref });
        return { val: true, message: '' };
      } catch (error) {
        console.log(error);
        return {
          val: false,
          message: 'An Error Occurred While Saving Shortlink',
        };
      }
    },
    delete: async ({ commit, state }, { index }) => {
      try {
        const shortLink = state.list[index];

        await shortLink.ref.delete();

        commit('delete', index);
        return true;
      } catch (error) {
        console.log(error);
        return false;
      }
    },
  },

  getters: {
    list: (state) => state.list,
  },
};

export default shortlink;
