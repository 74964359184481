<template>
  <el-aside class="event-settings-menu">
    <el-menu
      :default-openeds="['2', '3', '4']"
      class="el-menu-vertical-demo"
      :router="true"
      width="200"
    >
      <el-menu-item index="overview">
        <i class="el-icon-menu"></i>
        <span>Event Overview</span>
      </el-menu-item>

      <el-submenu index="2">
        <template slot="title">
          <i class="el-icon-set-up"></i>
          <span>Settings</span>
        </template>
        <!--<el-menu-item :index="`/event/${currentEvent.id}/infoandsettings`"
          >Info & Settings</el-menu-item
        >-->
        <el-menu-item :index="`/event/${currentEvent.id}/streamsettings`"
          >Stream Settings</el-menu-item
        >
        <el-menu-item index="2-3">Widgets</el-menu-item>
      </el-submenu>

      <el-submenu index="3">
        <template slot="title">
          <i class="el-icon-document"></i>
          <span>Page Settings</span>
        </template>
        <el-menu-item :index="`/event/${currentEvent.id}/pagesettings`"
          >Page Branding</el-menu-item
        >
        <el-menu-item :index="`/event/${currentEvent.id}/securitysettings`"
          >Security</el-menu-item
        >
        <el-menu-item :index="`/event/${currentEvent.id}/landingpage`"
          >Landing Page</el-menu-item
        >
        <el-menu-item index="registrationForm">Registration Form</el-menu-item>
        <el-menu-item :index="`/event/${currentEvent.id}/emails`"
          >Emails</el-menu-item
        >
      </el-submenu>
      <el-submenu index="4">
        <template slot="title">
          <i class="el-icon-pie-chart"></i>
          <span>Reports</span>
        </template>
        <el-menu-item index="whoisonline">Realtime Dashboard</el-menu-item>
        <el-menu-item index="registrations">Registrations</el-menu-item>
        <el-menu-item index="attendees">Attendees</el-menu-item>
        <el-menu-item index="chatMessages"> Chat Messages </el-menu-item>
      </el-submenu>
    </el-menu>
  </el-aside>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters({
      currentEvent: 'event/current',
    }),
  },
};
</script>

<style lang="scss" scoped>
.event-settings-menu {
  width: 240px !important;
  ul {
    height: calc(100vh - 180px);
  }
  .el-menu-item {
    color: #909399;
    &.is-active {
      color: #409eff;
    }
  }
}
</style>
