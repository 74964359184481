<!-- eslint-disable max-len -->
<template>
  <div>
    <div
      v-if="selectedIndex === '1'"
      class="settings-container"
      v-loading="loading"
    >
      <div class="settings-list">
        <div class="settings-item">
          <div class="label">Event Password</div>
          <el-switch v-model="form.eventPasswordEnabled"> </el-switch>
          <div class="settings-sub-el" v-if="form.eventPasswordEnabled">
            <el-input
              v-model="form.eventPassword"
              placeholder="Event Password"
            ></el-input>
          </div>
        </div>
        <div class="settings-item">
          <div class="label">IP White Listing</div>
          <el-switch v-model="form.ipWhiteListEnabled"> </el-switch>
          <div class="settings-sub-el" v-if="form.ipWhiteListEnabled">
            <el-input
              placeholder="Please add comma seperated list of IP address that you want to whitelist"
              v-model="form.ipWhiteList"
            ></el-input>
          </div>
        </div>
        <div class="settings-item">
          <div class="label">Email Domain White Listing</div>
          <el-switch v-model="form.emailDomainWhiteListEnabled"> </el-switch>
          <div class="settings-sub-el" v-if="form.emailDomainWhiteListEnabled">
            <el-input
              placeholder="Please add comma seperated list of email domain address that you want to whitelist"
              v-model="form.emailDomainWhiteList"
            ></el-input>
          </div>
        </div>
        <div class="settings-item">
          <div class="label">Referrer Domain White Listing</div>
          <el-switch v-model="form.referrerDomainWhiteListEnabled"> </el-switch>
          <div
            class="settings-sub-el"
            v-if="form.referrerDomainWhiteListEnabled"
          >
            <el-input
              placeholder="Please add comma seperated list of referrer domain address that you want to whitelist"
              v-model="form.referrerDomainWhiteList"
            ></el-input>
          </div>
        </div>
        <div class="settings-item">
          <div class="label">Participant List</div>
          <el-switch v-model="form.participantListEnabled"> </el-switch>
        </div>
        <div v-if="form.participantListEnabled" class="settings-item">
          <el-col :span="18">
            <el-upload
              class="upload-demo"
              action=""
              :file-list="fileList"
              ref="csvUpload"
              :auto-upload="false"
              :on-change="handleFileChange"
              accept=".csv"
              :limit="2"
            >
              <el-button slot="trigger" size="small" type="primary"
                >Select File</el-button
              ><el-button
                size="small"
                style="margin-left: 1rem;"
                type="success"
                @click="submitUpload"
                >Upload</el-button
              >
              <div slot="tip" class="el-upload__tip">
                Csv files only
              </div>
            </el-upload> </el-col
          ><el-col v-if="listUploaded" :span="6"
            ><el-button style="float: right" type="primary" @click="showList"
              >Show List</el-button
            ></el-col
          >
        </div>
        <div class="footer clearfix">
          <el-button style="float: right" type="primary" v-on:click="submit"
            >Save</el-button
          >
        </div>
      </div>
    </div>
    <el-container
      direction="vertical"
      v-if="selectedIndex === '2'"
      v-loading="loading"
    >
      <el-row>
        <el-button style="float: left" type="primary" v-on:click="goBack"
          >Go Back</el-button
        ></el-row
      >
      <el-row>
        <el-col :span="4"
          >Total Participants: {{ participantList.length }}</el-col
        >
        <el-col :offset="12" :span="4"
          ><el-button
            style="float: right;"
            type="primary"
            v-on:click="handleNew"
            >Add</el-button
          ></el-col
        ><el-col :span="4"
          ><el-button
            style="float: right;"
            type="danger"
            v-on:click="handleDelete"
            >Delete</el-button
          ></el-col
        >
      </el-row>
      <el-row>
        <el-table
          ref="partListTable"
          :data="participantList"
          @selection-change="handleSelectionChange"
          :border="true"
        >
          <el-table-column type="selection"> </el-table-column>
          <el-table-column prop="email" label="Email"> </el-table-column>
          <el-table-column prop="name" label="Name"> </el-table-column>
          <el-table-column prop="lastName" label="Last Name"> </el-table-column>
        </el-table>
      </el-row>
    </el-container>

    <el-dialog
      title="New Participant"
      :visible.sync="partDialogVisible"
      v-loading="modalLoading"
    >
      <el-form
        :model="partForm"
        label-position="left"
        :status-icon="true"
        :inline-message="true"
        ref="partForm"
        :rules="rules"
      >
        <el-form-item label="Name" prop="name">
          <el-input v-model="partForm.name" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="Last Name" prop="lastName">
          <el-input v-model="partForm.lastName" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="Email" prop="email">
          <el-input v-model="partForm.email" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button @click="partDialogVisible = false">Cancel</el-button>
        <el-button type="primary" @click="saveParticipant">Save</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
/* eslint-disable operator-linebreak */
/* eslint-disable no-unused-vars */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-plusplus */
/* eslint-disable no-underscore-dangle */

import axios from 'axios';

import { mapGetters, mapActions } from 'vuex';
import * as fb from '../../../firebase';

export default {
  computed: {
    ...mapGetters({
      currentEvent: 'event/current',
    }),
  },
  data() {
    return {
      form: {},
      fileList: [],
      listUploaded: false,
      loading: false,
      selectedIndex: '1',
      participantList: [],
      tableSelection: [],
      partDialogVisible: false,
      modalLoading: false,
      partForm: {},
      rules: {
        name: [{ required: true, message: 'Please Enter a Name' }],
        lastName: [
          {
            required: true,
            message: 'Please Enter a Last Name',
          },
        ],
        email: [
          {
            type: 'email',
            required: true,
            message: 'Please Enter an Email',
          },
        ],
      },
    };
  },
  async created() {
    Object.assign(this, { loading: true });
    const secSettingsSnap = await fb.db
      .doc(`event/${this.currentEvent.id}/settings/securitySettings`)
      .get();
    const secSettings = secSettingsSnap.data();
    const participantSnaps = await fb.db
      .collection(`event/${this.currentEvent.id}/participant`)
      .get();

    Object.assign(this, {
      form: { ...secSettings },
      listUploaded: !participantSnaps.empty,
      loading: false,
    });
  },
  methods: {
    ...mapActions({
      setSecuritySettings: 'event/setSecuritySettings',
    }),
    async submit() {
      Object.assign(this, { loading: true });

      Object.keys(this.form).forEach(
        (key) => this.form[key] === undefined && delete this.form[key],
      );

      const response = await this.setSecuritySettings({ form: this.form });

      if (response) {
        this.$notify({
          title: 'Success',
          message: 'Settings Saved Successfully',
          type: 'success',
        });
      } else {
        this.$notify.error({
          title: 'Error',
          message: 'An Error Occurred While Saving Settings',
        });
      }

      Object.assign(this, { loading: false });
    },
    handleFileChange(file, fileList) {
      if (fileList.length > 1) {
        const newFileList = fileList.splice(1, 1);

        Object.assign(this, { fileList: newFileList });
      }
    },
    async submitUpload() {
      try {
        const {
          $refs: {
            csvUpload: { uploadFiles },
          },
        } = this;

        if (uploadFiles.length > 0) {
          Object.assign(this, { loading: true });

          const { raw: file } = uploadFiles[0];
          const formData = new FormData();

          formData.append('file', file);
          formData.append('eventId', this.currentEvent.id);
          await axios({
            url: `${process.env.VUE_APP_BASE_URL}/csv-participant`,
            method: 'POST',
            headers: {
              'Content-Type': 'multipart/form-data',
            },
            data: formData,
          });
          Object.assign(this, {
            uploadModalVisible: true,
            listUploaded: true,
            loading: false,
          });
          return this.$notify({
            title: 'Success',
            message: 'Upload Completed',
            type: 'success',
          });
        }
        return true;
      } catch (error) {
        Object.assign(this, { loading: false });
        console.log(error);

        const message =
          error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : 'An Error Occurred While Uploading the List. Please Try Again.';

        return this.$notify.error({
          title: 'Error',
          message,
        });
      }
    },
    async showList() {
      try {
        Object.assign(this, { loading: true });

        const participantSnaps = await fb.db
          .collection(`event/${this.currentEvent.id}/participant`)
          .get();
        const participantList = [];

        participantSnaps.forEach((snap) => {
          participantList.push(snap.data());
        });
        return Object.assign(this, {
          selectedIndex: '2',
          participantList,
          loading: false,
        });
      } catch (error) {
        Object.assign(this, { selectedIndex: '1', loading: false });

        return this.$notify.error({
          title: 'Error',
          message: 'An error occurred while fetching the participants',
        });
      }
    },
    goBack() {
      Object.assign(this, {
        selectedIndex: '1',
      });
    },
    handleDelete() {
      if (this.tableSelection.length > 0) {
        this.$confirm(
          'Selected participants will be deleted. Are you sure?',
          'Delete Participants',
          {
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            type: 'warning',
          },
        )
          .then(() => {
            this.deleteParticipants();
          })
          .catch(() => {
            console.log('Deletion Cancelled');
          });
      }
    },
    async deleteParticipants() {
      try {
        Object.assign(this, { loading: true });

        const {
          tableSelection,
          currentEvent: { id: eventId },
          participantList,
        } = this;
        const { length } = tableSelection;
        let temp = participantList;

        for (let i = 0; i < length; i++) {
          const selection = tableSelection[i];
          const { _id } = selection;

          await fb.db.doc(`event/${eventId}/participant/${_id}`).delete();

          temp = temp.filter((obj) => obj._id !== selection._id);
        }

        Object.assign(this, { loading: false, participantList: temp });
        return this.$notify({
          title: 'Success',
          message: 'Participants Deleted Successfully',
          type: 'success',
        });
      } catch (error) {
        Object.assign(this, { loading: false });

        return this.$notify.error({
          title: 'Error',
          message: 'An error occurred while deleting the participants',
        });
      }
    },
    handleSelectionChange(val) {
      Object.assign(this, { tableSelection: val });
    },
    handleNew() {
      Object.assign(this, { partDialogVisible: true });
    },
    async saveParticipant() {
      try {
        Object.assign(this, { modalLoading: true });

        const {
          partForm,
          participantList,
          currentEvent: { id: eventId },
          $refs: { partForm: partFormRef },
        } = this;
        const validationResult = await new Promise((resolve) => {
          partFormRef.validate((valid) => {
            if (valid) {
              resolve(true);
            } else {
              resolve(false);
            }
          });
        });
        let fieldsToChange = {};
        let response;

        if (validationResult) {
          const partDoc = fb.db
            .collection(`event/${eventId}/participant`)
            .doc();
          const docData = {
            ...partForm,
            _id: partDoc.id,
            createdAt: fb.timestamp(),
          };

          await partDoc.set(docData);
          participantList.push(docData);

          fieldsToChange = {
            participantList,
            partDialogVisible: false,
            partForm: {},
          };
          response = this.$notify({
            title: 'Success',
            message: 'Participant Added Successfully',
            type: 'success',
          });
        } else {
          response = this.$notify.error({
            title: 'Error',
            message: 'Please Fill Missing/Incorrect Fields',
          });
        }

        Object.assign(this, { ...fieldsToChange, modalLoading: false });
        return response;
      } catch (error) {
        console.log(error);
        Object.assign(this, { modalLoading: false });

        return this.$notify.error({
          title: 'Error',
          message:
            'An error occurred while saving the participant. Please try again.',
        });
      }
    },
  },
};
</script>

<style>
.el-row {
  margin-top: 2rem;
}
</style>
