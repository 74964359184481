<template>
  <div class="settings-container regform-settings">
    <el-container direction="vertical" v-if="!loading">
      <el-row>
        <el-col style="overflow: auto; height: calc(100% - 150px)" :span="24">
          <draggable v-model="items" class="dragArea" group="people">
            <field-item
              @onEdit="openAsEdit(k)"
              :key="item.name"
              :field="item"
              v-for="(item, k) in items"
            ></field-item>
          </draggable>
        </el-col>
        <el-col :span="12">
          <el-button class="mt-2" @click="openAsNew()" type="success"
            >New Field</el-button
          >
        </el-col>
        <el-col style="text-align: right" :span="12">
          <el-button class="mt-2" @click="save()" type="danger">Save</el-button>
        </el-col>
      </el-row>

      <el-dialog
        title="Add New Field"
        :visible.sync="newFieldDialogVisible"
        width="40%"
      >
        <add-edit
          :field="editField"
          ref="addNewField"
          @save="addNewField"
          @close="closeFieldDialog()"
        ></add-edit>
      </el-dialog>
    </el-container>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import draggable from 'vuedraggable';
import FieldItem from '@/components/registrationForms/FieldItem.vue';
import AddEdit from '@/components/registrationForms/AddEdit.vue';

export default {
  name: 'List',
  data() {
    return {
      loading: false,
      items: [
        {
          name: 'Email',
          required: true,
          type: 'email',
          is_shown: true,
          is_mandatory: true,
          is_default: true,
        },
        {
          name: 'Name',
          required: true,
          type: 'name',
          is_shown: true,
          is_mandatory: true,
          is_default: true,
        },
        {
          name: 'Last Name',
          required: false,
          type: 'last_name',
          is_shown: false,
          is_default: true,
        },
        {
          name: 'Event Password',
          required: false,
          type: 'event_password',
          is_shown: false,
          is_default: true,
        },
        {
          name: 'TC Kimlik No',
          required: false,
          type: 'tcid',
          is_shown: false,
          is_default: true,
        },
        {
          name: 'Phone',
          required: false,
          type: 'phone',
          is_shown: false,
          is_default: true,
        },
        {
          name: 'Address',
          required: false,
          type: 'long_text',
          is_shown: false,
          is_default: true,
        },
        {
          name: 'City',
          required: false,
          type: 'short_text',
          is_shown: false,
          is_default: true,
        },
        {
          name: 'Country/Region',
          required: false,
          type: 'short_text',
          is_shown: false,
          is_default: true,
        },
        {
          name: 'Zip/Postal Code',
          required: false,
          type: 'short_text',
          is_shown: false,
          is_default: true,
        },
        {
          name: 'State/Province',
          required: false,
          type: 'short_text',
          is_shown: false,
          is_default: true,
        },
        {
          name: 'Organization',
          required: false,
          type: 'short_text',
          is_shown: false,
          is_default: true,
        },
        {
          name: 'Job Title',
          required: false,
          type: 'short_text',
          is_shown: false,
          is_default: true,
        },
        {
          name: 'Speciality',
          required: false,
          type: 'short_text',
          is_shown: false,
          is_default: true,
        },
      ],
      editField: {
        name: '',
        required: false,
        type: null,
        is_shown: false,
      },
      selectedIndex: -1,
      newFieldDialogVisible: false,
    };
  },
  components: { draggable, FieldItem, AddEdit },
  ...mapGetters({
    fields: 'regForm/list',
  }),
  created() {
    this.getFields({
      path: '',
      subscribe: false,
      order: { field: 'order' },
    }).then((res) => {
      const self = this;
      res.forEach((v) => {
        const currentItemIndex = self.items.findIndex((i) => i.name === v.name);
        const tempItem = {
          ...v,
        };
        console.log(tempItem);
        if (currentItemIndex === -1) {
          delete tempItem.id;
          self.items.splice(tempItem.order, 0, tempItem);
        } else {
          self.items[currentItemIndex].is_shown = tempItem.is_shown;
          self.items[currentItemIndex].required = tempItem.required;
          self.items[currentItemIndex].type = tempItem.type;
          self.items[currentItemIndex].order = tempItem.order;
          if (tempItem.helpText) {
            self.items[currentItemIndex].helpText = tempItem.helpText;
          }

          if (tempItem.options) {
            self.items[currentItemIndex].options = tempItem.options;
          }
        }
      });
      self.sortItems();
    });
  },
  watch: {
    fields: {
      deep: true,
      handler(val) {
        console.log(val);
      },
    },
    newFieldDialogVisible(val) {
      if (val && this.$refs.addNewField) {
        this.$refs.addNewField.formClear();
      }
    },
  },
  methods: {
    ...mapActions({
      updateAll: 'regForm/updateAll',
      getFields: 'regForm/find',
    }),
    openAsEdit(key) {
      this.selectedIndex = key;
      this.newFieldDialogVisible = true;
      this.editField = this.items[this.selectedIndex];
      const self = this;
      setTimeout(() => {
        self.$refs.addNewField.openAsEdit();
      }, 1);
    },
    openAsNew() {
      this.selectedIndex = -1;
      this.newFieldDialogVisible = true;
      this.editField = {
        name: '',
        required: false,
        type: null,
        is_shown: false,
      };
    },
    sortItems() {
      const self = this;
      self.items.sort((c, n) => {
        let r = -1;
        if (c.order === undefined) r = 1;
        else if (n.order === undefined) r = -1;
        else if (c.order - n.order > 0) r = 1;
        return r;
      });
    },
    save() {
      const self = this;
      const fields = [];
      this.items.forEach((v, i) => {
        /*
         if (v.is_shown) {
         }
       */
        const tempField = {
          order: i,
          ...{
            is_shown: v.is_shown,
            name: v.name,
            required: v.required,
            type: v.type,
            options: v.options,
            helpText: v.helpText,
            content: v.content,
          },
        };

        if (!tempField.options) {
          delete tempField.options;
        }
        if (!tempField.helpText) {
          delete tempField.helpText;
        }
        if (!tempField.content) {
          delete tempField.content;
        }
        fields.push(tempField);
      });
      console.log('---', fields);
      this.updateAll(fields)
        .then(() => {
          self.$message({
            message: 'Your changes saved successfully.',
            type: 'success',
          });
        })
        .catch(() => {
          self.$message.error('Something went wrong!');
        });
    },
    addNewField(data) {
      const cloneData = { ...data };
      if (this.selectedIndex === -1) {
        cloneData.is_shown = true;
        this.items.push(cloneData);
      } else {
        this.items[this.selectedIndex] = cloneData;
      }
      this.newFieldDialogVisible = false;
    },
    closeFieldDialog() {
      this.newFieldDialogVisible = false;
    },
  },
};
</script>

<style lang="scss">
.regform-settings {
  .field-card {
    .el-card__body {
      padding: 4px 10px;
      font-size: 14px;
      line-height: 28px;
    }
    margin-bottom: 4px;
  }
}
</style>
