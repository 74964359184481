/* eslint-disable no-underscore-dangle */
/* eslint-disable object-curly-newline */
/* eslint-disable no-unused-vars */

import axios from 'axios';

import { auth, db, timestamp } from '@/firebase';

const getters = {
  isAdmin: (state) => state.isAdmin,
};

const actions = {
  /* eslint-disable no-unused-vars */
  async login({ dispatch }, form) {
    // sign user in
    console.log(form);
    await auth.signInWithEmailAndPassword(form.email, form.password);

    // fetch user profile and set in state
    // dispatch('fetchUserProfile', user)
  },
  async logout({ dispatch }) {
    // sign user in
    await auth.signOut();

    // fetch user profile and set in state
    // dispatch('fetchUserProfile', user)
  },
  /* eslint-enable no-unused-vars */
  async fetchUserProfile() {
    // fetch user profile
    // await fb.usersCollection.doc(user.uid).get();
    // set user profile in state
    // commit('setUserProfile', userProfile.data())
    // change route to dashboard
    // router.push('/');
  },
  createUser: async (context, { name, role, email, password }) => {
    // backend'e create user talebinde bulun
    // bunun sebebi, client sdk'sında createuserwithemailandpassword
    // kullanıldığında otomatik olarak signin olması
    const {
      data: { uid },
    } = await axios({
      method: 'post',
      url: `${process.env.VUE_APP_BASE_URL}/user`,
      data: { email, password },
    });

    // eğer bir hatayla karşılaşılmazsa
    // user listesinde elimizdeki email'e ait kayıt var mı kontrol et
    const usersRef = await db
      .collection('user')
      .where('email', '==', email)
      .get();

    // eğer varsa
    if (usersRef.size >= 1) {
      let userRef;

      usersRef.forEach((snap) => {
        userRef = snap.ref;
      });

      // kaydı update'le
      await userRef.update({ name, role, email, updatedAt: timestamp() });
    } else {
      // eğer yoksa
      const userRef = db.collection('user').doc(uid);
      const _id = uid;

      // yeni kayıt oluştur
      await userRef.set({ name, email, role, _id, createdAt: timestamp() });
    }
  }, // kullanıcı admin mi değil mi kontrol etme
  checkIsAdmin: async ({ commit }) => {
    const { email = null } = auth.currentUser;

    // eğer email varsa
    if (email) {
      // user listesinde elimizdeki email'e ait kayıt var mı kontrol et
      const usersRef = await db
        .collection('user')
        .where('email', '==', email)
        .get();

      if (usersRef.size >= 1) {
        let user;

        usersRef.forEach((snap) => {
          user = snap.data();
        });

        // eğer kullanıcı varsa ve rolü adminse isAdmin değerini true yap
        if (user.role === 'admin') {
          commit('setIsAdmin', true);
        }
      }
    }
  },
};

const mutations = {
  setUserProfile(state, val) {
    state.userProfile = val;
  },
  setIsAdmin: (state, payload) => {
    state.isAdmin = payload;
  },
};
const state = {
  userProfile: {},
  isAdmin: false,
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
