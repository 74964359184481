import BaseModule from '@/store/baseFireVuex';
import { db } from '../../firebase';
// import { storage } from '../../firebase';

const schema = {
  name: {
    type: 'string',
    required: true,
  },
  required: {
    type: 'boolean',
    required: true,
  },
  is_shown: {
    type: 'boolean',
    required: true,
  },
  type: {
    type: 'string',
    required: true,
  },
  order: {
    type: 'number',
    required: true,
  },
  helpText: {
    type: 'string',
    required: false,
  },
  content: {
    type: 'string',
    required: false,
  },
  options: {
    type: 'Array',
    required: false,
  },
};

/* eslint-disable no-unused-vars */
const options = {
  name: 'RegistrationForm',
  getCollectionPath: (context) => {
    const { rootState } = context;
    return `/event/${rootState.event.current}/regForm`;
  },
  schema,
};

/* eslint-disable no-unused-vars */
class RegFormModule extends BaseModule {
  actions() {
    return {
      ...super.actions(),
      updateAll: async (context, payload) => {
        context.dispatch('deleteAll', (res) => {
          if (res) {
            const batch = db.batch();
            payload.forEach((doc) => {
              const docRef = db
                .collection(this.getCollectionPath(context))
                .doc();
              batch.set(docRef, doc);
            });
            batch.commit();
          }
        });
      },
      deleteAll: async (context, payload) => {
        const docRef = db.collection(this.getCollectionPath(context));
        docRef
          .get()
          .then((snapshot) => {
            if (snapshot.docs.length > 0) {
              snapshot.forEach((doc) => {
                doc.ref.delete();
                if (doc.id === snapshot.docs[snapshot.docs.length - 1].id) {
                  payload(true);
                }
              });
            } else {
              payload(true);
            }
          })
          .catch(() => {
            payload(false);
          });
      },
    };
  }
}

export default new RegFormModule(options).getModule();
/* eslint-enable max-classes-per-file */
