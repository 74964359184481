<template>
  <el-container direction="vertical">
    <Header />
    <el-main class="single">
      <div class="page-title">
        <span class="title">Short Links</span>
        <el-button
          type="primary"
          icon="el-icon-plus"
          style="float: right; margin-top: -10px"
          @click="triggerDialogForm()"
          >New</el-button
        >
      </div>
      <el-table :data="shortLinkList" :border="true" :fit="true" :key="index">
        <el-table-column prop="shortlink" label="Shortlink" width="180">
        </el-table-column>
        <el-table-column prop="to" label="Original URL"> </el-table-column>
        <el-table-column label="" width="151">
          <template slot-scope="scope">
            <el-button size="mini" @click="triggerEditForm(scope.$index)"
              >Edit</el-button
            >
            <el-button
              size="mini"
              type="danger"
              @click="triggerDeleteForm(scope.$index)"
              >Delete</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </el-main>
    <el-dialog title="Add Shortlink" :visible.sync="dialogFormVisible">
      <el-form
        :model="form"
        label-position="left"
        :label-width="formLabelWidth"
        :status-icon="true"
        :inline-message="true"
        ref="eventForm"
      >
        <el-form-item label="Shortlink" prop="shortlink">
          <el-input v-model="form.shortlink" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="Original URL" prop="to">
          <el-input v-model="form.to" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">Cancel</el-button>
        <el-button type="primary" @click="saveShortLink()">Save</el-button>
      </span>
    </el-dialog>
    <el-dialog title="Edit Shortlink" :visible.sync="editFormVisible">
      <el-form
        :model="editForm"
        label-position="left"
        :label-width="formLabelWidth"
        :status-icon="true"
        :inline-message="true"
        ref="eventForm"
      >
        <el-form-item label="Shortlink" prop="shortlink">
          <el-input v-model="editForm.shortlink" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="Original URL" prop="to">
          <el-input v-model="editForm.to" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button @click="editFormVisible = false">Cancel</el-button>
        <el-button type="primary" @click="handleEdit()">Edit</el-button>
      </span>
    </el-dialog>
    <el-dialog title="Delete Shortlink" :visible.sync="deleteFormVisible">
      <span>Are You Sure?</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="deleteFormVisible = false">Cancel</el-button>
        <el-button type="primary" @click="handleDelete(deleteIndex)"
          >Delete</el-button
        >
      </span>
    </el-dialog>
  </el-container>
</template>

<script>
/* eslint-disable no-unused-vars */
/* eslint-disable no-plusplus */
/* eslint-disable arrow-parens */
import { mapActions, mapState, mapGetters } from 'vuex';
import Header from '@/components/layout/Header.vue';

export default {
  components: {
    Header,
  },
  data() {
    return {
      form: {},
      editForm: {},
      dialogFormVisible: false,
      editFormVisible: false,
      deleteFormVisible: false,
      formLabelWidth: '200px',
      index: 0,
      deleteIndex: null,
    };
  },
  async created() {
    try {
      await this.fetchShortLinks();
    } catch (error) {
      console.log(error);
    }
  },
  computed: {
    ...mapState('shortlink', { shortLinkList: (state) => state.list }),
  },
  methods: {
    ...mapActions({
      fetchShortLinks: 'shortlink/fetchShortLinks',
      editShortLink: 'shortlink/editShortLink',
      save: 'shortlink/saveShortLink',
      delete: 'shortlink/delete',
    }),
    triggerDialogForm() {
      this.dialogFormVisible = true;
    },
    triggerEditForm(index) {
      this.editForm = { ...this.shortLinkList[index], index };
      this.editFormVisible = true;
    },
    triggerDeleteForm(index) {
      this.deleteFormVisible = true;
      this.deleteIndex = index;
    },
    async handleEdit() {
      Object.keys(this.editForm).forEach((key) => {
        if (typeof this.editForm[key] === 'string') {
          this.editForm[key] = this.editForm[key].toLowerCase();
          this.editForm[key] = this.editForm[key].replace(/\s/g, '');
        }
      });

      const response = await this.editShortLink(this.editForm);

      if (response.val) {
        this.$notify({
          title: 'Success',
          message: 'Shortlink Edited Successfully',
          type: 'success',
        });

        this.editFormVisible = false;
        this.editForm = {};
      } else {
        this.$notify.error({
          title: 'Error',
          message: response.message,
        });
      }

      this.index++;
    },
    async handleDelete(index) {
      const response = await this.delete({ index });

      if (response) {
        this.$notify({
          title: 'Success',
          message: 'Shortlink Deleted Successfully',
          type: 'success',
        });
      } else {
        this.$notify.error({
          title: 'Error',
          message: 'An Error Occurred While Deleting Shortlink',
        });
      }

      this.deleteFormVisible = false;
      this.index++;
      this.deleteIndex = null;
    },
    async saveShortLink() {
      Object.keys(this.form).forEach((key) => {
        if (typeof this.form[key] === 'string') {
          this.form[key] = this.form[key].toLowerCase();
          this.form[key] = this.form[key].replace(/\s/g, '');
        }
      });

      const response = await this.save({ form: this.form });

      if (response.val) {
        this.$notify({
          title: 'Success',
          message: 'Shortlink Saved Successfully',
          type: 'success',
        });

        this.dialogFormVisible = false;
        this.form = {};
      } else {
        this.$notify.error({
          title: 'Error',
          message: response.message,
        });
      }

      this.index++;
    },
  },
};
</script>

<style></style>
