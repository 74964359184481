<template>
  <div class="settings-container">
    <div class="settings-item">
      <div class="label">Receivers</div>
      <el-select v-model="form.type" placeholder="Select">
        <el-option label="Registrations" value="registration"></el-option>
        <el-option label="Attendees" value="attendee"></el-option>
      </el-select>
    </div>
    <div class="settings-item">
      <div class="label">Email Title</div>
      <el-input v-model="form.title"></el-input>
    </div>
    <div class="settings-item">
      <div class="label">Email Content</div>
      <vue-editor
        id="editor"
        useCustomImageHandler
        @image-added="handleImageAdded"
        v-model="form.content"
      ></vue-editor>
    </div>
    <div class="settings-item">
      <el-button type="primary" v-on:click="save" style="margin-bottom: 1rem"
        >Save</el-button
      >
    </div>
  </div>
</template>

<script>
/* eslint-disable import/order */
/* eslint-disable no-unused-vars */
/* eslint-disable indent */
/* eslint-disable comma-dangle */
/* eslint-disable object-curly-newline */

import { VueEditor } from 'vue2-editor';
import * as fb from '@/firebase';
import { mapGetters } from 'vuex';
import axios from 'axios';

export default {
  components: { VueEditor },
  computed: {
    ...mapGetters({
      currentEvent: 'event/current'
    })
  },
  async created() {
    try {
      const eventId = this.currentEvent.id;
      const registrationSettingsSnap = await fb.db
        .doc(`event/${eventId}/settings/registrationSettings`)
        .get();
      const registrationSettings = registrationSettingsSnap.data();

      Object.assign(this.form, {
        emailsEnabled: registrationSettings.emailsEnabled
          ? registrationSettings.emailsEnabled
          : false
      });
    } catch (error) {
      console.log(error);
    }
  },
  data() {
    return {
      form: {
        emailsEnabled: false,
        type: 'registration',
        title: '',
        content: ''
      }
    };
  },
  methods: {
    async save() {
      try {
        const eventId = this.currentEvent.id;
        const { title, content, type } = this.form;

        await axios({
          method: 'post',
          url: `${process.env.VUE_APP_BASE_URL}/send-group-mail`,
          data: {
            eventId,
            title,
            content,
            type
          }
        });

        this.$notify({
          title: 'Success',
          message: 'Mails Sent Successfully',
          type: 'success'
        });
      } catch (error) {
        console.log(error);
        this.$notify.error({
          title: 'Error',
          message: 'An Error Occurred While Sending the Mails'
        });
      }
    },
    async handleImageAdded(file, Editor, cursorLocation, resetUploader) {
      try {
        const storageRef = fb
          .storage()
          .ref()
          .child(`/${this.currentEvent.id}/${file.name}`);
        const picSnap = await storageRef.put(file);
        const picUrl = await picSnap.ref.getDownloadURL();

        Editor.insertEmbed(cursorLocation, 'image', picUrl);
        resetUploader();
      } catch (error) {
        console.log(error);

        this.$notify.error({
          title: 'Error',
          message: 'An Error Occurred While Uploading Image'
        });
      }
    }
  }
};
</script>

<style>
.settings-item {
  margin-bottom: 1rem;
}

.label {
  margin-bottom: 1rem;
}
</style>
