import BaseModule from '@/store/baseFireVuex';
import * as fb from '../../firebase';

/* eslint-disable max-classes-per-file */

const schema = {
  name: {
    type: 'string',
    required: true,
    // validator: (rule, value) => value === 'muji',
  },
  landingUrl: {
    type: 'string',
    required: false,
  },
  webinarCapacity: {
    type: 'object',
    required: false,
  },
};

/* eslint-disable no-unused-vars */
const options = {
  name: 'Account',
  getCollectionPath: (payload) => '/account',
  schema,
};

class AccountModule extends BaseModule {
  state() {
    return {
      ...super.state(),
      users: [],
    };
  }

  getters() {
    return {
      ...super.getters(),
      currentPath: (state) => `account/${state.current}`,
      users: (state) => state.users,
    };
  }

  mutations() {
    return {
      ...super.mutations(),
      editAccount: (state, { payload, index }) => {
        const { items } = state;

        items[index] = { ...items[index], ...payload };
        state.items = items;
      },
      deleteAccount: (state, payload) => {
        const { items } = state;

        items.splice(payload, 1);

        state.list = items;
      },
      updateAccount: (state, payload) => {
        const { id } = payload;
        const { items } = state;

        items.forEach((item, index) => {
          if (item.id === id) {
            items[index] = { ...item, ...payload };
          }
        });

        state.items = items;
      },
      updateUserList: (state, payload) => {
        const { users } = state;

        users.push(payload);

        state.users = users;
      },
      fetchUsers: (state, payload) => {
        state.users = payload;
      },
      deleteUser: (state, payload) => {
        const { users } = state;

        users.splice(payload, 1);

        state.users = users;
      },
      fetchAccounts: (state, payload) => {
        state.items = payload;
      },
    };
  }

  actions() {
    const getAndSet = async (context, assignId) => {
      let ac = null;
      console.log('get - set Acccount');
      await context
        .dispatch('get', { id: assignId })
        .then((account) => {
          console.log(account);
          ac = account;
        })
        .catch((err) => {
          console.log(err);
        });
      await context.dispatch('setCurrent', { id: assignId }).then(() => {});
      localStorage.setItem('mgnt_ac', assignId);
    };

    return {
      ...super.actions(),
      init: async (context, id) => {
        if (id) {
          await getAndSet(context, id);
          return context.getters.current;
        }
        if (context.getters.current !== undefined) {
          return context.getters.current;
        }
        if (localStorage.getItem('mgnt_ac')) {
          await getAndSet(context, localStorage.getItem('mgnt_ac'));
          return context.getters.current;
        }

        if (context.state.current) {
          await getAndSet(context, context.getters.current);
          return context.getters.current;
        }

        return context.getters.current;
      },
      saveAccount: async ({ commit }, { form }) => {
        try {
          const ref = fb.db.collection('account').doc();

          await ref.set({ ...form, createdAt: fb.timestamp() });

          commit('addItem', { ...form, id: ref.id });
          return true;
        } catch (error) {
          console.log(error);
          return false;
        }
      },
      editAccount: async ({ commit }, { name, id, index }) => {
        try {
          const ref = fb.db.doc(`account/${id}`);

          await ref.update({ name, updatedAt: fb.timestamp() });
          commit('editAccount', { payload: { name }, index });
          return true;
        } catch (error) {
          console.log(error);

          return false;
        }
      },
      deleteAccount: async ({ commit, state }, { index }) => {
        try {
          const account = state.items[index];

          await fb.db.doc(`account/${account.id}`).delete();
          commit('deleteAccount', index);
          return true;
        } catch (error) {
          console.log(error);
          return false;
        }
      },
      updateAccount: async ({ commit }, form) => {
        const accountRef = await fb.db.doc(`account/${form.id}`).get();

        // eğer account varsa
        if (accountRef.exists) {
          // ismini güncelle
          await fb.db.doc(`account/${form.id}`).update({
            name: form.name,
            ...(form.landingUrl
              ? { landingUrl: form.landingUrl }
              : { landingUrl: '' }),
            ...(form.webinarCapacity
              ? { webinarCapacity: form.webinarCapacity }
              : { webinarCapacity: { presenter: 2, attendee: 1000 } }),
            updatedAt: fb.timestamp(),
          });
          commit('updateAccount', form);
        } else {
          throw new Error('Account Does Not Exist.');
        }
      },
      addNewUser: async ({ commit }, { email, role, accountId }) => {
        const accountRef = await fb.db.doc(`account/${accountId}`).get();

        // eğer account yoksa hata oluştur
        if (!accountRef.exists) {
          throw new Error('Account Does Not Exist.');
        }

        // user koleksiyonunda verilen email'e ait bir kayıt var mı kontrol et
        const usersRef = await fb.db
          .collection('user')
          .where('email', '==', email)
          .get();

        // eğer yoksa hata oluştur
        if (usersRef.size < 1) {
          throw new Error('User Does Not Exist');
        }

        let user;
        let userId;
        let userRef;

        // user ve id değerlerini tut
        usersRef.forEach((snap) => {
          user = snap.data();
          userId = snap.id;
          userRef = snap.ref;
        });

        const account = accountRef.data();

        // eğer account kaydında moderator array'i varsa
        // ve içinde bu id'de bir kullanıcı zaten varsa hata döndür
        if (account.moderator) {
          account.moderator.forEach(({ id }) => {
            if (id === userId) {
              throw new Error('User Already Exists');
            }
          });
        }

        // eğer account kaydında manager array'i varsa
        // ve içinde bu id'de bir kullanıcı zaten varsa hata döndür
        if (account.manager) {
          account.manager.forEach(({ id }) => {
            if (id === userId) {
              throw new Error('User Already Exists');
            }
          });
        }

        // users array'ini account'ta verilen role ait array varsa ona eşitle
        // yoksa boş array olarak oluştur
        const users = account[role] ? account[role] : [];

        // elimizdeki kullanıcı id'si ve ismini array'e ekle
        users.push({ id: userId, name: user.name });

        // account kaydını güncel array'le güncelle
        await fb.db
          .doc(`account/${accountId}`)
          .update({ [role]: users, updatedAt: fb.timestamp() });

        // user'ın halihazırda account objesi var mı yok mu kontrol et
        const userAccountObj = user.account ? user.account : {};

        // user'ın kaydedildiği accountId'yi ve rolü gösteren key-value pair'i account objesine ekle
        Object.assign(userAccountObj, { [accountId]: role });
        // user dokümanını yeni account objesiyle birlikte güncelle
        await userRef.update({
          account: userAccountObj,
          updatedAt: fb.timestamp(),
        });
        // değişiklikleri store state'ine yansıt
        commit('updateUserList', { name: user.name, role, id: userId });
      },
      // account'a ait kullanıcıları bulup store'a kaydetme
      fetchUsers: async ({ commit }, id) => {
        const accountRef = await fb.db.doc(`account/${id}`).get();

        // eğer account yoksa hata ver
        if (!accountRef.exists) {
          throw new Error('Account Does Not Exist.');
        }

        const account = accountRef.data();
        const managers = account.manager ? account.manager : [];
        const moderators = account.moderator ? account.moderator : [];
        const users = [];

        // manager kayıtlarını al
        managers.forEach((manager) => {
          users.push({ ...manager, role: 'manager' });
        });
        // moderator kayıtlarını al
        moderators.forEach((moderator) => {
          users.push({ ...moderator, role: 'moderator' });
        });

        // state'e yansıt
        commit('fetchUsers', users);
      },
      deleteUser: async ({ commit, state }, { index, accountId }) => {
        const { users } = state;
        const user = users[index];
        const accountRef = await fb.db.doc(`account/${accountId}`).get();

        // eğer account yoksa hata ver
        if (!accountRef.exists) {
          throw new Error('Account Does Not Exist.');
        }

        const account = accountRef.data();

        // eğer account'ta user'ın rolüne ait array bulunmuyorsa hata döndür
        if (!account[user.role]) {
          throw new Error('User Does Not Exist');
        }

        // array'den silinmesi istenen id'ye ait kaydı çıkar
        const accountUsers = account[user.role].filter(
          (obj) => obj.id !== user.id,
        );

        // account kaydını güncelle
        await fb.db
          .doc(`account/${accountId}`)
          .update({ [user.role]: accountUsers, updatedAt: fb.timestamp() });
        // store'u güncelle
        commit('deleteUser', index);
      },
      filterAccounts: async ({ commit }) => {
        const { email = null } = fb.auth.currentUser;

        // eğer current user'a ait email yoksa hata döndür
        if (!email) {
          throw new Error();
        }

        // elimizdeki email'e ait user kayıtlarını al
        const usersRef = await fb.db
          .collection('user')
          .where('email', '==', email)
          .get();

        // eğer hiç user yoksa hata ver
        if (usersRef.size < 1) {
          throw new Error('User Does Not Exist');
        }

        let user;
        let userId;

        usersRef.forEach((snap) => {
          user = snap.data();
          userId = snap.id;
        });

        // eğer user role'ü admin ise
        if (user.role === 'admin') {
          const accountsRef = await fb.db.collection('account').get();
          const accounts = [];

          // tüm account kayıtlarını listele
          accountsRef.forEach((snap) => {
            accounts.push({ ...snap.data(), id: snap.id });
          });

          commit('fetchAccounts', accounts);
        } else {
          // eğer admin değilse
          const accounts = [];
          // sadece örnekteki objeyi içeren account kayıtlarını bul ve listele
          const managersRef = await fb.db
            .collection('account')
            .where('manager', 'array-contains', {
              name: user.name,
              id: userId,
            })
            .get();
          const moderatorsRef = await fb.db
            .collection('account')
            .where('moderator', 'array-contains', {
              name: user.name,
              id: userId,
            })
            .get();

          managersRef.forEach((snap) => {
            accounts.push({ ...snap.data(), id: snap.id });
          });
          moderatorsRef.forEach((snap) => {
            accounts.push({ ...snap.data(), id: snap.id });
          });

          commit('fetchAccounts', accounts);
        }
      },
    };
  }
}

export default new AccountModule(options).getModule();
/* eslint-enable max-classes-per-file */
