/* eslint-disable consistent-return */
/* eslint-disable no-underscore-dangle */
/* eslint-disable arrow-parens */
/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */

import * as fb from '../../firebase';

const sessions = {
  namespaced: true,

  state: {
    list: [],
  },

  mutations: {
    fetchAll: (state, sessions) => {
      Object.assign(state, { list: sessions });
    },
  },

  actions: {
    start: async ({ rootGetters, dispatch }, payload) => {
      try {
        const {
          'event/current': { id: eventId },
        } = rootGetters;
        const sessionRef = fb.db.collection(`event/${eventId}/session`).doc();
        const sessionId = sessionRef.id;

        await sessionRef.set({ startAt: fb.timestamp(), _id: sessionId });

        const session = (await sessionRef.get()).data();
        let response = false;

        if (session.startAt) {
          await fb.db.doc(`event/${eventId}`).update({
            status: 'live',
            updatedAt: fb.timestamp(),
            currentSession: sessionId,
          });
          dispatch('event/get', { id: eventId }, { root: true });

          response = true;
        }

        return response;
      } catch (error) {
        console.log(error);
      }
    },
    stop: async ({ rootGetters, dispatch }, payload) => {
      try {
        const {
          'event/current': { id: eventId },
        } = rootGetters;
        const sessionSnap = await fb.db
          .collection(`event/${eventId}/session`)
          .orderBy('startAt', 'desc')
          .limit(1)
          .get();
        let session = null;
        let sessionRef;
        let response = false;

        sessionSnap.forEach((snap) => {
          session = snap.data();
          sessionRef = snap.ref;
        });

        if (session) {
          await sessionRef.update({
            endAt: fb.timestamp(),
            updatedAt: fb.timestamp(),
          });

          if ((await sessionRef.get()).data().endAt) {
            await fb.db
              .doc(`event/${eventId}`)
              .update({ status: 'upcoming', updatedAt: fb.timestamp() });
            dispatch('event/get', { id: eventId }, { root: true });

            response = true;
          }
        }

        return response;
      } catch (error) {
        console.log(error);
      }
    },
    end: async ({ rootGetters, dispatch }) => {
      try {
        const {
          'event/current': { id: eventId },
        } = rootGetters;
        const sessionSnap = await fb.db
          .collection(`event/${eventId}/session`)
          .orderBy('startAt', 'desc')
          .limit(1)
          .get();
        let session = null;
        let sessionRef;

        sessionSnap.forEach((snap) => {
          session = snap.data();
          sessionRef = snap.ref;
        });

        if (session && !session.endAt) {
          await sessionRef.update({
            updatedAt: fb.timestamp(),
            endAt: fb.timestamp(),
          });
        }

        await fb.db.doc(`event/${eventId}`).update({
          status: 'completed',
          endAt: fb.timestamp(),
          updatedAt: fb.timestamp(),
        });
        dispatch('event/get', { id: eventId }, { root: true });
      } catch (error) {
        console.log(error);
      }
    },
    fetchAll: async ({ rootGetters, commit }) => {
      try {
        const {
          'event/current': { id: eventId },
        } = rootGetters;
        const sessionsRef = await fb.db
          .collection(`event/${eventId}/session`)
          .orderBy('startAt', 'desc')
          .get();
        const sessions = [];

        sessionsRef.forEach((snap) => {
          sessions.push({ ...snap.data(), ref: snap.ref });
        });

        commit('fetchAll', sessions);
      } catch (error) {
        console.log(error);
      }
    },
  },

  getters: {
    list: (state) => state.list,
  },
};

export default sessions;
