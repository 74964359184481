<template>
  <el-container direction="vertical">
    <Header />
    <el-main class="single">
      <div class="page-title">
        <span class="title">Accounts</span>
        <el-button
          type="primary"
          icon="el-icon-plus"
          style="float: right; margin-top: -10px"
          @click="triggerDialogForm()"
          >New</el-button
        >
      </div>
      <el-table :data="accountList" :border="true" :fit="true" :key="index">
        <el-table-column prop="name" label="Name"> </el-table-column>
        <el-table-column width="180">
          <template slot-scope="scope">
            <el-button
              size="mini"
              @click="triggerEditForm(scope.row, scope.$index)"
              >Edit</el-button
            >
            <el-button
              size="mini"
              type="danger"
              @click="triggerDeleteForm(scope.$index)"
              >Delete</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </el-main>
    <el-dialog title="Add Account" :visible.sync="dialogFormVisible">
      <el-form
        :model="form"
        label-position="left"
        :label-width="formLabelWidth"
        :status-icon="true"
        :inline-message="true"
        ref="eventForm"
      >
        <el-form-item label="Name" prop="name">
          <el-input v-model="form.name" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">Cancel</el-button>
        <el-button type="primary" @click="saveAccount()">Save</el-button>
      </span>
    </el-dialog>
    <el-dialog title="Edit Account" :visible.sync="editFormVisible">
      <el-form
        :model="editForm"
        label-position="left"
        :label-width="formLabelWidth"
        :status-icon="true"
        :inline-message="true"
        ref="eventForm"
      >
        <el-form-item label="Name" prop="name">
          <el-input v-model="editForm.name" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button @click="editFormVisible = false">Cancel</el-button>
        <el-button type="primary" @click="handleEdit()">Edit</el-button>
      </span>
    </el-dialog>
    <el-dialog title="Delete Account" :visible.sync="deleteFormVisible">
      <span>Are You Sure?</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="deleteFormVisible = false">Cancel</el-button>
        <el-button type="primary" @click="handleDelete(deleteIndex)"
          >Delete</el-button
        >
      </span>
    </el-dialog>
  </el-container>
</template>

<script>
/* eslint-disable no-unused-vars */
/* eslint-disable no-plusplus */
/* eslint-disable arrow-parens */

import { mapActions, mapState, mapGetters } from 'vuex';
import Header from '@/components/layout/Header.vue';

export default {
  components: {
    Header,
  },
  data() {
    return {
      form: {},
      editForm: {},
      dialogFormVisible: false,
      editFormVisible: false,
      deleteFormVisible: false,
      formLabelWidth: '200px',
      index: 0,
      deleteIndex: null,
    };
  },
  async created() {
    try {
      // ilgili kullanıcıya ait account listesini filtrele
      await this.filterAccounts();
    } catch (error) {
      console.log(error);
      this.$alert(error.message, 'Error!', {
        confirmButtonText: 'OK',
        type: 'error',
      });
    }
  },
  computed: {
    ...mapState('account', { accountList: (state) => state.items }),
  },
  methods: {
    ...mapActions({
      accountListAction: 'account/find',
      filterAccounts: 'account/filterAccounts',
      save: 'account/saveAccount',
      editAccount: 'account/editAccount',
      deleteAccount: 'account/deleteAccount',
    }),
    triggerDialogForm() {
      this.dialogFormVisible = true;
    },
    triggerEditForm(row, index) {
      // edit'e tıklandığında ilgili id'ye ait edit sayfasına yönlendir
      this.$router.push({ path: `/account/edit/${row.id}` });
    },
    triggerDeleteForm(index) {
      this.deleteFormVisible = true;
      this.deleteIndex = index;
    },
    async saveAccount() {
      const response = await this.save({ form: this.form });

      if (response) {
        this.$notify({
          title: 'Success',
          message: 'Account Saved Successfully',
          type: 'success',
        });
      } else {
        this.$notify.error({
          title: 'Error',
          message: 'An Error Occurred While Saving Account',
        });
      }
      this.dialogFormVisible = false;
      this.index++;
      this.form = {};
    },
    async handleEdit() {
      const response = await this.editAccount(this.editForm);

      if (response) {
        this.$notify({
          title: 'Success',
          message: 'Account Edited Successfully',
          type: 'success',
        });
      } else {
        this.$notify.error({
          title: 'Error',
          message: 'An Error Occurred While Editing Account',
        });
      }

      this.editFormVisible = false;
      this.editForm = {};
      this.index++;
    },
    async handleDelete(index) {
      const response = await this.deleteAccount({ index });

      if (response) {
        this.$notify({
          title: 'Success',
          message: 'Account Deleted Successfully',
          type: 'success',
        });
      } else {
        this.$notify.error({
          title: 'Error',
          message: 'An Error Occurred While Deleting Account',
        });
      }

      this.deleteFormVisible = false;
      this.index++;
      this.deleteIndex = null;
    },
  },
};
</script>

<style></style>
