<template>
  <div id="app">
    <router-view v-if="isReady" />
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  data() {
    return {
      isReady: false,
    };
  },
  computed: {
    ...mapGetters({
      currentEvent: 'event/current',
      currentAccount: 'account/current',
    }),
  },
  methods: {
    ...mapActions({
      getAccountAction: 'account/get',
      initAccountAction: 'account/initAccount',
    }),
  },
  async created() {
    /*
    console.log(store.state.event.current);
    if(store.state.event.current && this.currentEvent === undefined){
      this.getEventAction(store.state.event.current);
    }
    */

    this.isReady = true;
  },
};
</script>
<style lang="scss">
</style>
