<template>
  <el-container direction="vertical">
    <Header />
    <el-main class="single">
      <div class="page-title">
        <span class="title">Events</span>
        <el-button
          type="primary"
          icon="el-icon-plus"
          style="float: right; margin-top: -10px"
          @click="triggerDialogForm()"
          >New Event</el-button
        >
      </div>
      <el-table
        :data="eventList"
        :border="true"
        :fit="true"
        v-loading="loading"
      >
        <el-table-column label="Start" width="180">
          <template slot-scope="scope" v-if="scope.row.startAt">
            {{ scope.row.startAt | moment('L hh:mm') }}
          </template>
        </el-table-column>
        <el-table-column prop="title" label="Title"> </el-table-column>
        <el-table-column prop="status" label="Status"> </el-table-column>
        <el-table-column label="" width="151">
          <template slot-scope="scope">
            <el-button size="mini" @click="handleEdit(scope.$index, scope.row)"
              >Edit</el-button
            >
            <el-button
              size="mini"
              type="danger"
              @click="handleDelete(scope.$index, scope.row)"
              >Delete</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </el-main>
    <el-dialog
      title="Event"
      :visible.sync="dialogFormVisible"
      v-loading="saveDisabled"
      element-loading-text="Creating New Event..."
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.8)"
    >
      <el-form
        :model="form"
        label-position="left"
        :label-width="formLabelWidth"
        :rules="schema"
        :status-icon="true"
        :inline-message="true"
        ref="eventForm"
      >
        <el-form-item label="Event Title" prop="title">
          <el-input v-model="form.title" autocomplete="off"></el-input>
        </el-form-item>

        <el-form-item label="Start" prop="startAt">
          <el-date-picker
            ref="startAt"
            v-model="form.startAt"
            format="yyyy-MM-dd HH:mm"
            :default-value="new Date()"
            type="datetime"
            placeholder="Select date and time"
          >
          </el-date-picker>
        </el-form-item>

        <el-form-item label="End" prop="endAt">
          <el-date-picker
            v-model="form.endAt"
            format="yyyy-MM-dd HH:mm"
            type="datetime"
            placeholder="Select date and time"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="Event Type" prop="type">
          <el-select v-model="form.type" placeholder="Select">
            <el-option label="Webinar" value="webinar" />
            <el-option label="Theatre" value="theatre" />
            <el-option label="Live" value="live" />
            <el-option label="IR Webcast" value="irwebcast" />
            <el-option label="Zoom Webinar" value="zoomwebinar" />
          </el-select>
        </el-form-item>
        <el-form-item label="Default Language" prop="interfaceLang">
          <el-select v-model="form.interfaceLang" placeholder="Select">
            <el-option label="English" value="en" />
            <el-option label="Turkish" value="tr" />
            <el-option label="Spanish" value="es" />
          </el-select>
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">Cancel</el-button>
        <el-button type="primary" @click="saveEvent()" :disabled="saveDisabled"
          >Save</el-button
        >
      </span>
    </el-dialog>
  </el-container>
</template>

<script>
// @ is an alias to /src
/* eslint-disable no-underscore-dangle */

import Header from '@/components/layout/Header.vue';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'EventList',
  components: {
    Header,
  },
  data() {
    return {
      form: {},
      formLabelWidth: '200px',
      dialogFormVisible: false,
      tableData: [],
      saveDisabled: false,
    };
  },
  computed: {
    ...mapGetters({
      eventList: 'event/list',
      loading: 'event/loading',
      schema: 'event/schema',
      currentAccount: 'account/current',
      accountPath: 'account/currentPath',
    }),
  },
  methods: {
    ...mapActions({
      setCurrent: 'event/setCurrent',
      findEvents: 'event/find',
      deleteEventAction: 'event/delete',
      saveEventAction: 'event/save',
      clearState: 'event/clearItems',
      unSubscribe: 'event/unSubscribe',
    }),
    triggerDialogForm() {
      const self = this;
      this.dialogFormVisible = true;

      setTimeout(() => {
        self.$refs.startAt.$emit('input', new Date());
      }, 100);
    },
    async saveEvent() {
      this.saveDisabled = true;
      this.form.accountId = this.currentAccount.id;
      await this.$refs.eventForm.validate(async (valid) => {
        if (!valid) {
          this.$alert('Please complete all required fields', 'Error!', {
            confirmButtonText: 'OK',
            type: 'error',
          });
        }
        console.log('validated');
        return null;
      });

      this.saveEventAction({ data: this.form })
        .then((doc) => {
          console.log(doc);
          this.dialogFormVisible = false;
          this.$refs.eventForm.resetFields();
          this.saveDisabled = false;
          this.$router.push(`/event/${doc._id}/overview`);
        })
        .catch((error) => {
          this.$alert(error.message, 'Error!', {
            confirmButtonText: 'OK',
            type: 'error',
          });
          this.saveDisabled = false;
        })
        .finally(() => {});
    },
    handleEdit(index, item) {
      console.log(index, item);
      // this.form = { ...item };
      // this.triggerDialogForm();
      this.setCurrent(item);
      this.$router.push(`/event/${item.id}/overview`);
    },
    handleDelete(index, item) {
      console.log(index, item.id);
      this.$confirm(
        'Are you sure want to delete this event?',
        'Confirmation!',
        {
          type: 'warning',
        },
      ).then(() => {
        console.log('before delete');
        this.deleteEventAction({ id: item.id });
      });

      // this.$router.push(`/event/${item.id}`);
    },
  },
  created() {
    this.clearState();
    this.findEvents({
      query: [['accountId', '==', this.currentAccount.id]],
      order: { field: 'startAt', desc: true },
      subscribe: false,
    })
      .then((items) => {
        console.log(items);
      })
      .catch((error) => {
        this.$alert(error.message, 'Error!', {
          confirmButtonText: 'OK',
          type: 'error',
        });
      });
  },
  destroyed() {
    this.unSubscribe();
  },
};
</script>
