<template>
  <el-container>
    <Header />

    <el-container v-if="isReady" direction="vertical">
      <EventHeader />
      <el-container direction="horizontal" class="wrapper">
        <EventSettingsMenu />
        <el-main>
          <router-view />
        </el-main>
      </el-container>
    </el-container>
    <el-footer class="main-footer">
      <span class="font-size-small">Magnet (c) 2021</span></el-footer
    >
  </el-container>
</template>

<script>
/* eslint-disable comma-dangle */
import { mapActions } from 'vuex';
import EventSettingsMenu from '@/components/event/EventSettingsMenu.vue';
import Header from '@/components/layout/Header.vue';
import EventHeader from '@/components/event/EventHeader.vue';

export default {
  data() {
    return {
      isReady: false,
    };
  },
  components: {
    EventSettingsMenu,
    Header,
    EventHeader,
  },
  methods: {
    ...mapActions({
      initEvent: 'event/init',
      setCurrentEvent: 'event/setCurrent',
    }),
  },
  created() {
    this.initEvent(this.$route.params.id).then(() => {
      this.isReady = true;
    });
  },
};
</script>

<style lang="scss">
</style>
