/* eslint-disable no-else-return */
/* eslint-disable no-restricted-globals */
/* eslint-disable operator-linebreak */
/* eslint-disable object-curly-newline */
/* eslint-disable import/order */
/* eslint-disable consistent-return */
/* eslint-disable no-underscore-dangle */
/* eslint-disable arrow-parens */
/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */

import * as fb from '../../firebase';
import moment from 'moment';
import _ from 'lodash';

const aggregateAttendees = (attendeesWithoutAggregation) => {
  const aggregatedAttendees = _(attendeesWithoutAggregation)
    .groupBy((x) => x._id)
    .map((val, key) => ({
      _id: key,
      joinedAt: val[0].createdAt,
      registration: _.pick(val[val.length - 1], [
        'approved',
        'createdAt',
        'device',
        'eventId',
        'info',
        'location',
        'name',
        'uid',
        'updatedAt',
      ]),
      metrics: {
        timers: {
          watch: _.sumBy(val, 'metrics.timers.watch'),
          buffering: _.sumBy(val, 'metrics.timers.buffering'),
          latency: _.sumBy(val, 'metrics.timers.latency'),
          pause: _.sumBy(val, 'metrics.timers.pause'),
          session: _.sumBy(val, 'metrics.timers.session'),
          startup: _.sumBy(val, 'metrics.timers.startup'),
        },
        counters: {
          play: _.sumBy(val, 'metrics.counters.play'),
          pause: _.sumBy(val, 'metrics.counters.pause'),
          error: _.sumBy(val, 'metrics.counters.error'),
          buffering: _.sumBy(val, 'metrics.counters.buffering'),
          decodedFrames: _.sumBy(val, 'metrics.counters.decodedFrames'),
          droppedFrames: _.sumBy(val, 'metrics.counters.droppedFrames'),
          fps: Math.max(
            ...val.map((el) => {
              if (
                el.metrics &&
                el.metrics.counters &&
                el.metrics.counters.fps &&
                !isNaN(el.metrics.counters.fps)
              ) {
                return el.metrics.counters.fps;
              } else {
                return 0;
              }
            }),
          ),
          changeLevel: _.sumBy(val, 'metrics.counters.changeLevel'),
          seek: _.sumBy(val, 'metrics.counters.seek'),
          fullscreen: _.sumBy(val, 'metrics.counters.fullscreen'),
          dvrUsage: _.sumBy(val, 'metrics.counters.dvrUsage'),
        },
      },
    }))
    .value();
  const attendees = [];

  aggregatedAttendees.forEach((attendee) => {
    const joinedAt = moment(attendee.joinedAt).format('HH:mm:ss');
    const { location, info } = attendee.registration;
    let sessionDuration = 0;
    let timeOnPlayer = 0;

    if (attendee.metrics && attendee.metrics.timers) {
      if (attendee.metrics.timers.watch) {
        sessionDuration = (attendee.metrics.timers.watch / 60000).toFixed(1);
      }
      if (attendee.metrics.timers.session) {
        timeOnPlayer = (attendee.metrics.timers.session / 60000).toFixed(1);
      }
    }

    const { device } = attendee.registration;

    attendees.push({
      joinedAt,
      sessionDuration,
      timeOnPlayer,
      location,
      info,
      device,
    });
  });

  return attendees;
};

const attendee = {
  namespaced: true,

  state: {
    list: [],
    columns: [],
    count: 0,
  },

  mutations: {
    fetchAll: (state, attendees) => {
      Object.assign(state, { list: attendees });
    },
    setColumns: (state, columns) => {
      Object.assign(state, { columns });
    },
    setCount: (state, count) => {
      Object.assign(state, { count });
    },
  },

  actions: {
    fetchAll: async ({ rootGetters, commit }, { sessionId, streamId }) => {
      try {
        const {
          'event/current': { id: eventId },
        } = rootGetters;
        const attendeesRef = fb
          .database()
          .ref(`event/${eventId}/${sessionId}`)
          .orderByChild('streamId')
          .equalTo(streamId);
        const attendeesWithoutAggregation = await new Promise(
          (resolve, reject) => {
            attendeesRef.once('value', (snapshot) => {
              resolve(_.values(snapshot.val()));
            });
          },
        );
        const attendees = aggregateAttendees(attendeesWithoutAggregation);

        commit('fetchAll', attendees);
      } catch (error) {
        console.log(error);
      }
    },
    loadAllAttendees: async ({ rootGetters, commit }, { sessionId }) => {
      try {
        const {
          'event/current': { id: eventId },
        } = rootGetters;
        const eventRef = fb.database().ref(`event/${eventId}/${sessionId}`);
        const allAttendeesArray = await new Promise((resolve, reject) => {
          eventRef.once('value', (snapshot) => {
            resolve(_.values(snapshot.val()));
          });
        });
        const attendees = aggregateAttendees(allAttendeesArray);

        commit('fetchAll', attendees);
      } catch (error) {
        console.log(error);
      }
    },
    getTotalCount: async ({ rootGetters, commit }) => {
      try {
        const {
          'event/current': { id: eventId },
        } = rootGetters;
        const firestoreEventRef = await fb.db.doc(`event/${eventId}`).get();
        const event = firestoreEventRef.data();
        const sessionId = event.currentSession ? event.currentSession : '';

        if (sessionId === '') {
          commit('setCount', 0);
        } else {
          const eventRef = fb.database().ref(`event/${eventId}/${sessionId}`);
          const allAttendeesArray = await new Promise((resolve, reject) => {
            eventRef.once('value', (snapshot) => {
              resolve(_.values(snapshot.val()));
            });
          });
          const attendees = aggregateAttendees(allAttendeesArray);
          const attendeeCount = attendees.length;

          commit('setCount', attendeeCount);
        }
      } catch (error) {
        console.log(error);
      }
    },
  },

  getters: {
    list: (state) => state.list,
    columns: (state) => state.columns,
    count: (state) => state.count,
  },
};

export default attendee;
