<template>
  <el-container>
    <Header />
    <el-main>
      <router-view />
    </el-main>
    <el-footer class="main-footer">
      <span class="font-size-small">Magnet (c) 2021</span></el-footer
    >
  </el-container>
</template>

<script>
import Header from '@/components/layout/Header.vue';

export default {
  components: {
    Header,
  },
};
</script>

<style>
</style>
