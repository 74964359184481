<template>
  <div class="settings-container">
    <div class="settings-item">
      <el-button type="primary" v-on:click="save" style="margin-bottom: 1rem;"
        >Save</el-button
      >
    </div>
    <vue-editor
      id="editor"
      useCustomImageHandler
      @image-added="handleImageAdded"
      v-model="htmlFormEditor"
    ></vue-editor>
  </div>
</template>

<script>
/* eslint-disable import/order */
/* eslint-disable no-unused-vars */

import { VueEditor } from 'vue2-editor';
import * as fb from '../../../firebase';
import { mapGetters } from 'vuex';

export default {
  components: { VueEditor },
  computed: {
    ...mapGetters({
      currentEvent: 'event/current',
    }),
  },
  async created() {
    try {
      const pageSettingsSnap = await fb.db
        .doc(`event/${this.currentEvent.id}/settings/pageSettings`)
        .get();

      if (pageSettingsSnap.exists) {
        const { landingContent = '' } = pageSettingsSnap.data();

        Object.assign(this, { htmlFormEditor: landingContent });
      }
    } catch (error) {
      console.log(error);
    }
  },
  data() {
    return { htmlFormEditor: '' };
  },
  methods: {
    async save() {
      try {
        const pageSettingsSnap = await fb.db
          .doc(`event/${this.currentEvent.id}/settings/pageSettings`)
          .get();

        if (pageSettingsSnap.exists) {
          await fb.db
            .doc(`event/${this.currentEvent.id}/settings/pageSettings`)
            .update({
              landingContent: this.htmlFormEditor,
              updatedAt: fb.timestamp(),
            });
        } else {
          await fb.db
            .doc(`event/${this.currentEvent.id}/settings/pageSettings`)
            .set({
              landingContent: this.htmlFormEditor,
              createdAt: fb.timestamp(),
            });
        }

        this.$notify({
          title: 'Success',
          message: 'Settings Saved Successfully',
          type: 'success',
        });
      } catch (error) {
        console.log(error);
        this.$notify.error({
          title: 'Error',
          message: 'An Error Occurred While Saving Settings',
        });
      }
    },
    async handleImageAdded(file, Editor, cursorLocation, resetUploader) {
      try {
        const storageRef = fb
          .storage()
          .ref()
          .child(`/${this.currentEvent.id}/${file.name}`);
        const picSnap = await storageRef.put(file);
        const picUrl = await picSnap.ref.getDownloadURL();

        Editor.insertEmbed(cursorLocation, 'image', picUrl);
        resetUploader();
      } catch (error) {
        console.log(error);

        this.$notify.error({
          title: 'Error',
          message: 'An Error Occurred While Uploading Image',
        });
      }
    },
  },
};
</script>

<style></style>
