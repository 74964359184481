<template>
  <el-container direction="vertical" v-if="!loading">
    <p>Total Messages: {{ this.messageCount }}</p>
    <el-table
      ref="chatTable"
      :data="chatList"
      :border="true"
      :fit="true"
      size="small"
      v-loading="loading"
      height="100%"
    >
      <el-table-column prop="userName" label="Name" width="250">
      </el-table-column>
      <el-table-column prop="message" label="Message"> </el-table-column>
      <el-table-column label="Created At" width="120">
        <template slot-scope="scope">
          {{ scope.row.createdAt | moment('MMM DD, kk:mm') }}
        </template>
      </el-table-column>
    </el-table>
  </el-container>
</template>

<script>
/* eslint-disable no-underscore-dangle */
import { mapGetters } from 'vuex';
import { database } from '@/firebase';
import _ from 'lodash';

export default {
  data() {
    return {
      chatList: [],
      loading: false,
      messageCount: 0,
    };
  },
  computed: {
    ...mapGetters({
      currentEvent: 'event/current',
    }),
  },
  methods: {},
  mounted() {
    this.loading = true;
    const onlineRef = database()
      .ref(`chat/${this.currentEvent.id}`)
      .orderByKey();
    onlineRef.on('value', (snapshot) => {
      const list = _.values(snapshot.toJSON());
      this.chatList = _.orderBy(list, 'createdAt', 'desc');
      this.messageCount = this.chatList.length;
      this.loading = false;
    });

    /*
    const onlineRef = database().ref(`attendees/${this.event.id}`)
      .orderByChild('boothVisit/QxjYhen90Od59dfwqqu9').startAt(0);
    onlineRef.once('value').then((snapshot) => {
      const list = _.values(snapshot.val());
      // console.log(_);
      this.onlineList = list;
      this.loading = false;
    });
    */
  },
};
</script>

<style lang="scss">
.el-table {
  flex-grow: 100;
  align-self: stretch;
}
</style>
