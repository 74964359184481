<template>
  <div>
    <p>Total Attendees: {{ totalAttendees }}</p>

    <el-select v-model="session" placeholder="Select">
      <el-option
        v-for="item in sessionList"
        :key="item._id"
        :label="item.label"
        :value="`${item._id}`"
        v-on:click="() => onChange(item)"
        v-loading="loading"
      >
      </el-option>
    </el-select>

    <el-select v-model="stream" style="margin-left: 1rem" placeholder="Select">
      <el-option
        v-for="item in streamsList"
        :key="item._id"
        :label="item.name"
        :value="item._id"
      >
      </el-option>
    </el-select>
    <el-button
      type="primary"
      style="margin-left: 1rem"
      v-on:click="getAttendees()"
      >Load Attendees</el-button
    >
    <el-button
      type="success"
      style="margin-left: 1rem"
      v-on:click="downloadCsv()"
      >Export</el-button
    >
    <div v-if="!loading">
      <el-table
        :data="attendees"
        border
        style="margin-top: 2rem"
        size="small"
        default-expand-all
      >
        <el-table-column prop="joinedAt" label="Joined At"></el-table-column>
        <el-table-column
          v-for="formItem in formFields"
          :key="formItem._id"
          :prop="'info.' + getKebab(formItem.name)"
          :label="getStartCase(formItem.name)"
          width="220"
          :formatter="
            (row, column, cellValue, index) =>
              cellValue ? cellValue.toString() : ''
          "
        >
        </el-table-column>
        <el-table-column prop="sessionDuration" label="Session Duration">
        </el-table-column>
        <el-table-column prop="timeOnPlayer" label="Time On Player">
        </el-table-column>
        <el-table-column
          v-for="deviceItem in deviceFields"
          :key="deviceItem._id"
          :prop="'device.' + deviceItem.name"
          :label="getStartCase(deviceItem.name)"
          width="150"
          :formatter="
            (row, column, cellValue, index) =>
              cellValue ? cellValue.toString() : ''
          "
        >
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
/* eslint-disable import/newline-after-import */
/* eslint-disable no-unused-vars */
/* eslint-disable no-underscore-dangle */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable operator-linebreak */

import { mapGetters, mapActions } from 'vuex';
import moment from 'moment';
import _ from 'lodash';

export default {
  data() {
    return {
      session: '',
      stream: '',
      sessionList: [],
      streamsList: [],
      regFormFields: [],
      loading: false,
      totalAttendees: 0,
    };
  },
  async created() {
    try {
      this.loading = true;

      // session ve stream'leri getir
      await this.fetchSessions();
      await this.fetchStreams();

      // date'leri formatla
      this.sessions.forEach((session) => {
        const startAt = moment(session.startAt.toDate()).format(
          'MMMM D, YYYY k:m A',
        );
        const endAt = session.endAt
          ? moment(session.endAt.toDate()).format('MMMM D, YYYY k:m A')
          : '';

        this.sessionList.push({
          ...session,
          label: `${startAt} - ${endAt}`,
        });
      });

      // current session'ı, session listesinde birinci gelene eşitle (en son session olduğu için)
      this.session = this.sessionList.length > 0 ? this.sessionList[0]._id : '';

      this.streams.forEach((stream) => {
        this.streamsList.push(stream);
      });

      // stream listesinin başına all seçeneğini koy
      this.streamsList.unshift({ _id: '', name: 'All' });

      this.stream = this.streamsList[0]._id;

      // son session'a ait tüm katılımcıları sistemden çek
      await this.loadAllAttendees({
        sessionId: this.session,
      });

      this.totalAttendees = this.attendees.length;

      // reg form bilgilerini getir
      this.regformFields = await this.getFields({
        path: '',
        subscribe: false,
        order: { field: 'order' },
      });

      this.loading = false;
    } catch (error) {
      console.log(error);
    }
  },
  computed: {
    ...mapGetters({
      currentEvent: 'event/current',
      sessions: 'sessions/list',
      streams: 'streams/currentStreams',
      attendees: 'attendee/list',
    }),
    formFields() {
      // görüntülenmesi gereken ve html content olmayan form field'larını seç
      return _.filter(
        this.regformFields,
        (value, key) => value.is_shown && value.type !== 'html_content',
      );
    },
    deviceFields() {
      // görüntülenmesi gereken device field'ları
      return [
        { name: 'browser' },
        { name: 'type' },
        { name: 'os' },
        { name: 'ip' },
        { name: 'city' },
        { name: 'country' },
      ];
    },
  },
  methods: {
    ...mapActions({
      fetchSessions: 'sessions/fetchAll',
      fetchStreams: 'streams/fetchCurrentStreams',
      fetchAttendees: 'attendee/fetchAll',
      loadAllAttendees: 'attendee/loadAllAttendees',
      getFields: 'regForm/find',
    }),
    async getAttendees() {
      try {
        if (this.session !== '') {
          this.loading = true;

          // eğer belli bir stream seçilmemişse (yani all seçeneği aktise)
          // o session'a ait tüm katılımcıları al
          if (this.stream === '') {
            await this.loadAllAttendees({ sessionId: this.session });
          } else {
            // eğer streamId varsa o stream'e ait katılmcıları al
            await this.fetchAttendees({
              streamId: this.stream,
              sessionId: this.session,
            });
          }

          // toplam katılımcı sayısı
          this.totalAttendees = this.attendees.length;

          this.loading = false;
        }
      } catch (error) {
        console.log(error);
      }
    },
    getKebab(v) {
      return _.kebabCase(v);
    },
    getStartCase(v) {
      return _.startCase(v);
    },
    downloadCsv() {
      // export edilecek field'ların listesi
      const fields = this.getSortedFields();
      // düzenlenmiş field bilgileri
      const attendees = this.sanitizeData();
      // csv dosyasının ilk satırını field isimleriyle oluşturma
      let csv = _.join(fields, ',');

      csv += '\n';

      // her katılımcıyı yeni satıra ekleme
      attendees.forEach((row) => {
        csv += _.values(row).join(',');
        csv += '\n';
      });

      const hiddenElement = document.createElement('a');

      hiddenElement.href = `data:text/csv;charset=utf-8,${encodeURI(csv)}`;
      hiddenElement.target = '_blank';
      hiddenElement.download = 'attendees.csv';
      hiddenElement.click();
    },
    sanitizeData() {
      const unsanitizedAttendees = [];

      this.attendees.forEach((attendee) => {
        const { info, device, ...restOf } = attendee;
        const tempAttendee = {};

        // info ya da device içinde olmayan field'ları temp katılımcı kaydına ekleme
        // field key'ler start case olacak şekilde
        Object.keys(restOf).forEach((key) => {
          tempAttendee[this.getStartCase(key)] = restOf[key];
        });

        // aynı şeyi device objesi içindeki field'lar için yap
        if (device) {
          Object.keys(device).forEach((key) => {
            if (key !== '_id') {
              if (key === 'city' || key === 'country') {
                tempAttendee[`Ip ${this.getStartCase(key)}`] = device[key];
              } else {
                tempAttendee[this.getStartCase(key)] = device[key];
              }
            }
          });
        }

        // aynı şeyi info objesi içindeki field'lar için yap
        if (info) {
          Object.keys(info).forEach((key) => {
            tempAttendee[this.getStartCase(key)] = info[key];
          });
        }

        unsanitizedAttendees.push(tempAttendee);
      });

      const fields = this.getSortedFields();
      const attendees = [];

      // katılımcı listesini alfabetize edilmiş field listesine göre düzenle
      unsanitizedAttendees.forEach((attendee) => {
        const tempAttendee = {};

        fields.forEach((field) => {
          Object.assign(tempAttendee, {
            [field]: attendee[field] ? attendee[field] : '',
          });
        });

        attendees.push(tempAttendee);
      });

      return attendees;
    },
    getSortedFields() {
      // regform'dan gelen görüntülenmesi gereken ve html content olmayan field'ları seç
      const regFields = _.filter(
        this.regformFields,
        (value, key) => value.is_shown && value.type !== 'html_content',
      );
      let fields = [];

      regFields.forEach((field) => {
        fields.push(field.name);
      });

      // regform dışında rapora yansıtılması gereken field'lar
      const otherFields = [
        'browser',
        'type',
        'os',
        'ip',
        'ipCity',
        'ipCountry',
        'sessionDuration',
        'timeOnPlayer',
        'joinedAt',
      ];

      fields = fields.concat(otherFields);

      // isimleri alfabetize ve start case görünecek şekilde düzenle ve döndür
      return fields.map((field) => {
        const tempField = this.getKebab(field);

        return this.getStartCase(tempField);
      });
    },
  },
};
</script>

<style></style>
