<template>
  <el-container direction="vertical" v-if="!loading">
    <p>Current Connections: {{ this.currentConnectionCount }}</p>
    <el-table
      ref="peopleTable"
      :data="onlineList"
      :border="true"
      :fit="true"
      size="small"
      v-loading="loading"
      height="100%"
    >
      <el-table-column
        v-for="formItem in formFields"
        :key="formItem._id"
        :prop="'info.' + getKebab(formItem.name)"
        :label="getStartCase(formItem.name)"
        width="150"
        :formatter="
          (row, column, cellValue, index) =>
            cellValue ? cellValue.toString() : ''
        "
      >
      </el-table-column>

      <el-table-column prop="device.browser" label="Browser" width="100">
      </el-table-column>
      <el-table-column prop="device.city" label="Ip City" width="250">
      </el-table-column>
      <el-table-column prop="device.country" label="Ip Country" width="250">
      </el-table-column>

      <el-table-column prop="device.os" label="Os" width="120">
      </el-table-column>
      <el-table-column prop="device.type" label="Type" width="150">
      </el-table-column>
      <el-table-column prop="device.ip" label="Ip" width="120">
      </el-table-column>
      <el-table-column label="Last Update" width="120">
        <template slot-scope="scope">
          {{ scope.row.updatedAt | moment('MMM DD, kk:mm') }}
        </template>
      </el-table-column>
    </el-table>
  </el-container>
</template>

<script>
/* eslint-disable no-underscore-dangle */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable operator-linebreak */
/* eslint-disable no-unused-vars */
/* eslint-disable indent */

import { mapGetters, mapActions } from 'vuex';
import { database, db } from '@/firebase';
import _ from 'lodash';

export default {
  data() {
    return {
      onlineList: [],
      loading: false,
      currentConnectionCount: 0,
      regformFields: [],
    };
  },
  computed: {
    ...mapGetters({
      currentEvent: 'event/current',
    }),
    formFields() {
      return _.filter(
        this.regformFields,
        (value, key) => value.is_shown && value.type !== 'html_content',
      );
    },
  },
  methods: {
    ...mapActions({
      getFields: 'regForm/find',
    }),
    getKebab(v) {
      return _.kebabCase(v);
    },
    getStartCase(v) {
      return _.startCase(v);
    },
  },
  async mounted() {
    this.loading = true;

    const eventRef = await db.doc(`event/${this.currentEvent.id}`).get();
    const event = eventRef.data();

    if (event.currentSession) {
      const onlineRef =
        event.type === 'webinar'
          ? database()
              .ref(`event/${this.currentEvent.id}/${event.currentSession}`)
              .orderByChild('status')
              .equalTo('connected')
          : database()
              .ref(`event/${this.currentEvent.id}/${event.currentSession}`)
              .orderByChild('status')
              .equalTo('playing');

      onlineRef.on('value', (snapshot) => {
        const list = _.values(snapshot.toJSON());

        this.onlineList = _.orderBy(list, 'createdAt', 'desc');
        this.currentConnectionCount = this.onlineList.length;
        this.loading = false;
      });
    }

    this.regformFields = await this.getFields({
      path: '',
      subscribe: false,
      order: { field: 'order' },
    });
  },
};
</script>

<style lang="scss">
.el-table {
  flex-grow: 100;
  align-self: stretch;
}
</style>
