<template>
  <el-container direction="vertical">
    <Header />
    <el-main class="single">
      <div class="page-title">
        <span class="title">Users</span>
        <el-button
          type="primary"
          icon="el-icon-plus"
          style="float: right; margin-top: -10px"
          @click="addUser()"
          >New User</el-button
        >
      </div>
      <el-table :data="userList" :border="true" :fit="true" :key="index">
        <el-table-column prop="name" label="Name"> </el-table-column>
        <el-table-column prop="email" label="Email"> </el-table-column>
        <el-table-column width="180">
          <template slot-scope="scope">
            <el-button
              size="mini"
              @click="triggerEditForm(scope.row, scope.$index)"
              >Edit</el-button
            >
            <el-button
              size="mini"
              type="danger"
              @click="triggerDeleteForm(scope.$index)"
              >Delete</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </el-main>
  </el-container>
</template>

<script>
/* eslint-disable no-unused-vars */
/* eslint-disable no-plusplus */
/* eslint-disable arrow-parens */

import { mapActions, mapState, mapGetters } from 'vuex';
import Header from '@/components/layout/Header.vue';
import * as fb from '@/firebase';

export default {
  components: {
    Header,
  },
  data() {
    return {
      userList: [],
      formLabelWidth: '200px',
      index: 0,
      deleteIndex: null,
    };
  },
  async created() {
    try {
      const userListSnapshot = await fb.db.collection('user').get();
      userListSnapshot.forEach(doc => {
        this.userList.push(doc.data());
      });
    } catch (error) {
      console.log(error);
      this.$alert(error.message, 'Error!', {
        confirmButtonText: 'OK',
        type: 'error',
      });
    }
  },
  computed: {
    ...mapState('auth', { accountList: (state) => state.items }),
  },
  methods: {
    ...mapActions({
      userListAction: 'auth/find',
    }),
    addUser() {
      // add user'a tıklandığında yönlendir
      this.$router.push({ path: '/user/new' });
    },

    async handleEdit() {
      const response = await this.editAccount(this.editForm);

      if (response) {
        this.$notify({
          title: 'Success',
          message: 'Account Edited Successfully',
          type: 'success',
        });
      } else {
        this.$notify.error({
          title: 'Error',
          message: 'An Error Occurred While Editing Account',
        });
      }

      this.editFormVisible = false;
      this.editForm = {};
      this.index++;
    },
    async handleDelete(index) {
      const response = await this.deleteAccount({ index });

      if (response) {
        this.$notify({
          title: 'Success',
          message: 'Account Deleted Successfully',
          type: 'success',
        });
      } else {
        this.$notify.error({
          title: 'Error',
          message: 'An Error Occurred While Deleting Account',
        });
      }

      this.deleteFormVisible = false;
      this.index++;
      this.deleteIndex = null;
    },
  },
};
</script>

<style></style>
