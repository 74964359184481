<template>
  <div :key="pageIndex">
    <el-row :gutter="20">
      <el-col :span="12"
        ><el-card class="box-card" shadow="never">
          <div slot="header" class="clearfix">
            <span>Event Info</span>
            <el-button
              style="float: right; padding: 3px 0"
              type="text"
              v-on:click="triggerDialogForm()"
              >Edit</el-button
            >
          </div>
          <div class="">
            <h4>{{ event.title }}</h4>
            <p class="text-muted fs-6">
              {{ event.startAt | moment('LLL') }} -
              {{ event.endAt | moment('LLL') }}
            </p>
            <p>
              <b>Interface Language:</b>
              {{ getInterfaceLanguageLabel(event.interfaceLang) }}
            </p>
            <p><b>Type:</b> {{ getEventTypeValue() }}</p>
            <p v-if="event.type === 'zoomwebinar'">
              <b>Zoom Meeting Id:</b> {{ event.meetingId }}
            </p>
          </div>
        </el-card>
        <el-card class="box-card" shadow="never" style="margin-top: 20px">
          <div slot="header" class="clearfix">
            <span>Embed code</span>
            <el-button
              style="float: right"
              type="info"
              size="small"
              aria-label="Embed code"
              v-clipboard:copy="embedCode"
              v-clipboard:success="onCopy"
              >Copy</el-button
            >
          </div>
          <div>
            <el-input
              type="textarea"
              :rows="4"
              placeholder="Please input"
              v-model="embedCode"
            >
            </el-input>
          </div>
        </el-card>
      </el-col>

      <el-col :span="12">
        <el-card class="box-card" shadow="never" style="margin-bottom: 1rem">
          <el-row style="text-align: center">
            <el-col :span="8">Current Connections</el-col>
            <el-col :span="8">Attendees</el-col>
            <el-col :span="8">Registrations</el-col>
          </el-row>
          <el-row style="text-align: center">
            <el-col :span="8">{{ connections }}</el-col>
            <el-col :span="8">{{ attendeeCount }}</el-col>
            <el-col :span="8">{{ totalRegistrations }}</el-col>
          </el-row>
        </el-card>
        <el-card class="box-card" shadow="never" style="margin-bottom: 1rem">
          <div slot="header" class="clearfix">
            <span>Control Room</span>
          </div>
          <div style="float: right">
            <el-button
              :type="event.status === 'live' ? 'danger' : 'success'"
              v-on:click="event.status === 'live' ? stop() : start()"
              >{{ event.status === 'live' ? 'Stop' : 'Start' }}</el-button
            >
            <el-button type="info" v-on:click="end" style="margin-bottom: 1rem"
              >End Event</el-button
            >
          </div>
        </el-card>
        <el-card class="box-card" shadow="never">
          <div slot="header" class="clearfix">
            <span>Links</span>
            <el-button style="float: right; padding: 3px 0" type="text"
              >Copy Instructions</el-button
            >
          </div>
          <el-form>
            <el-form-item label="Short link">
              <el-input v-model="shortLinkSlug">
                <template slot="prepend">https://join.livecasthq.com/</template>
                <el-button
                  type="primary"
                  slot="append"
                  icon="el-icon-edit"
                  aria-label="Short link"
                  @click="editShortLink"
                >
                </el-button>
                <el-button
                  type="primary"
                  slot="append"
                  icon="el-icon-copy-document"
                  aria-label="Short link"
                  v-clipboard:copy="shortLinkUrl"
                  v-clipboard:success="onCopy"
                  :disabled="shortLinkCopyDisabled"
                ></el-button
              ></el-input>
            </el-form-item>
            <el-form-item label="Registration">
              <el-input v-model="landingUrl"
                ><el-button
                  type="primary"
                  slot="append"
                  icon="el-icon-copy-document"
                  aria-label="Registration link"
                  v-clipboard:copy="regLink"
                  v-clipboard:success="onCopy"
                ></el-button
              ></el-input>
            </el-form-item>
            <el-form-item label="Q&A Moderation">
              <el-input v-model="qnaUrl"
                ><el-button
                  type="primary"
                  slot="append"
                  icon="el-icon-copy-document"
                  aria-label="Q&A Moderation URL"
                  v-clipboard:copy="qnaUrl"
                  v-clipboard:success="onCopy"
                ></el-button
              ></el-input>
              <el-switch
                style="margin-top: 1rem"
                active-text="Moderation Enabled"
                inactive-text="Moderation Disabled"
                v-model="moderationEnabled"
                @change="changeQnaModeration"
              >
              </el-switch>
              <el-switch
                style="margin-top: 1rem; width: 100%"
                active-text="QnA Enabled"
                inactive-text="Qna Disabled"
                v-model="qnaEnabled"
                @change="changeQnaEnabled"
              >
              </el-switch>
              <el-button
                style="float: right; margin-top: 1rem"
                type="primary"
                size="small"
                v-on:click="redirectToQnaModeration()"
                >Go to Moderation</el-button
              >
              <el-button
                v-if="event.type === 'webinar'"
                style="float: left; margin-top: 1rem"
                type="primary"
                size="small"
                v-on:click="redirectToEvent()"
                >Go to Event</el-button
              >
            </el-form-item>
          </el-form>
        </el-card>
      </el-col>
    </el-row>
    <el-dialog title="Event" :visible.sync="dialogFormVisible">
      <el-form
        :model="form"
        label-position="left"
        :label-width="formLabelWidth"
        :status-icon="true"
        :inline-message="true"
        ref="eventForm"
      >
        <el-form-item label="Event Title" prop="title">
          <el-input v-model="form.title" autocomplete="off"></el-input>
        </el-form-item>

        <el-form-item label="Start" prop="startAt">
          <el-date-picker
            v-model="form.startAt"
            format="yyyy-MM-dd HH:mm"
            type="datetime"
            placeholder="Select date and time"
          >
          </el-date-picker>
        </el-form-item>

        <el-form-item label="End" prop="endAt">
          <el-date-picker
            v-model="form.endAt"
            format="yyyy-MM-dd HH:mm"
            type="datetime"
            placeholder="Select date and time"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="Event Type" prop="type">
          <el-select v-model="form.type" placeholder="Select">
            <el-option label="Webinar" value="webinar" />
            <el-option label="Theatre" value="theatre" />
            <el-option label="Live" value="live" />
            <el-option label="IR Webcast" value="irwebcast" />
            <el-option label="Zoom Webinar" value="zoomwebinar" />
            <el-option label="Custom" value="custom" />
          </el-select>
        </el-form-item>
        <el-form-item
          v-if="form.type === 'zoomwebinar'"
          label="Zoom Meeting Id"
          prop="meetingId"
        >
          <el-input v-model="form.meetingId" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item
          label="Custom Live Url"
          prop="custom"
          v-if="form.type === 'custom'"
        >
          <el-input v-model="form.customLiveUrl" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="Default Language" prop="interfaceLang">
          <el-select v-model="form.interfaceLang" placeholder="Select">
            <el-option
              v-for="item in languages"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">Cancel</el-button>
        <el-button type="primary" @click="editEvent()">Save</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
/* eslint-disable max-len */
/* eslint-disable no-underscore-dangle */
/* eslint-disable consistent-return */
/* eslint-disable no-plusplus */

import { mapActions, mapGetters } from 'vuex';
import moment from 'moment';
// import _ from 'lodash';
import axios from 'axios';

import * as fb from '../../firebase';

export default {
  computed: {
    ...mapGetters({
      eventList: 'event/list',
      event: 'event/current',
      schema: 'event/schema',
      currentConnectionCount: 'connections/count',
      attendeeCount: 'attendee/count',
      landingUrl: 'event/landingUrl',
    }),
    shortLinkSlug() {
      return this.event.shortLink;
    },
    shortLinkUrl() {
      return `https://join.livecasthq.com/${this.event.shortLink}`;
    },
    regLink() {
      console.log(this.event);
      return this.landingUrl;
    },
    qnaUrl() {
      console.log(this.event);
      return `https://qna.livecasthq.com/${this.event.id}`;
    },
    embedCode() {
      return `<iframe src="https://embed2.livecasthq.com/${this.event.id}" width=840 height="780" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>`;
    },
    shortLinkCopyDisabled() {
      if (this.event.shortLink) {
        const shortlink = this.event.shortLink.replace(/\s/g, '');

        if (shortlink === '') {
          return true;
        }

        return false;
      }

      return true;
    },
  },
  methods: {
    ...mapActions({
      setCurrent: 'event/setCurrent',
      findEvents: 'event/find',
      getEvent: 'event/get',
      deleteEventAction: 'event/delete',
      saveEventAction: 'event/save',
      clearState: 'event/clearItems',
      unSubscribe: 'event/unSubscribe',
      editInfo: 'event/editInfo',
      saveShortLink: 'shortlink/saveShortLink',
      updateEvent: 'event/update',
      startSession: 'sessions/start',
      stopSession: 'sessions/stop',
      endEvent: 'sessions/end',
      findRegistrationsAction: 'registration/find',
      clearRegistrationState: 'registration/clearItems',
      getCount: 'connections/getCount',
      getAttendeeCount: 'attendee/getTotalCount',
      getLandingUrl: 'event/getLandingUrl',
    }),
    getEventTypeValue() {
      const types = [
        { name: 'webinar', label: 'Webinar' },
        { name: 'theatre', label: 'Theatre' },
        { name: 'live', label: 'Live' },
        { name: 'irwebcast', label: 'IR Webcast' },
        { name: 'zoomwebinar', label: 'Zoom Webinar' },
        { name: 'custom', label: 'Custom' },
      ];
      let label;

      types.forEach((type) => {
        if (this.event.type === type.name) {
          label = type.label;
        }
      });

      return label;
    },
    onCopy(e) {
      console.log(e);
      this.$notify({
        title: 'Copied!',
        message: `${e.trigger.ariaLabel} copied to clipboard`,
        type: 'success',
      });
    },
    editShortLink() {
      this.$prompt('Please input a shortlink for this event', 'Shortlink', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        inputPlaceholder: this.event.shortLink,
      })
        .then(async ({ value }) => {
          // shortlink'i küçük harfli yap ve boşlukları temizle
          const shortlink = value.toLowerCase().replace(/\s/g, '');
          // shortlink ve to field değerlerini güncelle
          const response = await this.saveShortLink({
            form: {
              shortlink,
              to: this.landingUrl,
            },
          });

          // eğer shortlink'i update/save ederken hata oluşmuşsa uyarı oluştur
          if (!response.val) {
            return this.$notify.error({
              title: 'Error',
              message: response.message,
            });
          }

          let eventEditResponse = false;

          // eğer shortlink işleminde problem oluşmamışsa, event'in shortlink değerini güncelle
          if (response.val) {
            eventEditResponse = await this.editInfo({
              form: { shortLink: shortlink },
            });
          }

          // eğer event'i güncellerken problem oluşmamışsa başarı mesajı oluştur
          if (eventEditResponse) {
            this.$notify({
              title: 'Success',
              message: 'Shortlink Saved Successfully',
              type: 'success',
            });

            this.pageIndex++;
          } else {
            // eğer event'i güncellerken hata yaşanmışsa uyarı mesajı çıkar
            this.$notify.error({
              title: 'Error',
              message: 'An Error Occurred While Saving Shortlink',
            });
          }
        })
        .catch(() => {});
    },
    async editEvent() {
      // eğer undefined field varsa form'dan sil
      Object.keys(this.form).forEach(
        (key) => this.form[key] === undefined && delete this.form[key],
      );

      // event bilgilerini güncelle
      const response = await this.editInfo({
        form: this.form,
      });

      if (response) {
        this.$notify({
          title: 'Success',
          message: 'Settings Saved Successfully',
          type: 'success',
        });

        this.pageIndex++;
      } else {
        this.$notify.error({
          title: 'Error',
          message: 'An Error Occurred While Saving Settings',
        });
      }

      this.dialogFormVisible = false;
      this.form = {};
    },
    triggerDialogForm() {
      this.form = {
        ...this.event,
        startAt: moment(this.event.startAt.toDate()),
        endAt: moment(this.event.endAt.toDate()),
      };
      this.dialogFormVisible = true;
    },
    async changeQnaModeration(val) {
      try {
        // event'in qnasettings belgesini al
        const qnaSettingsRef = await fb.db
          .doc(`event/${this.event.id}/settings/qnaSettings`)
          .get();

        // eğer ref bulunuyorsa qnamoderation değerini güncelle
        if (qnaSettingsRef.exists) {
          await fb.db
            .doc(`event/${this.event.id}/settings/qnaSettings`)
            .update({ qnaModeration: val, updatedAt: fb.timestamp() });
        } else {
          // eğer ref bulunmuyorsa qnaSettings dokümanı oluştur ve ilgili değerlerle kaydet
          await fb.db.doc(`event/${this.event.id}/settings/qnaSettings`).set({
            qnaModeration: val,
            createdAt: fb.timestamp(),
            eventId: this.event.id,
          });
        }

        this.$notify({
          title: 'Success',
          message: 'QnA Moderation Settings Changed Successfully',
          type: 'success',
        });

        this.pageIndex++;
      } catch (error) {
        console.log(error);

        this.$notify.error({
          title: 'Error',
          message: 'An Error Occurred While Changing QnA Moderation Settings',
        });
      }
    },
    async changeQnaEnabled(val) {
      try {
        // qnaEnabled field'ını güncelle
        await fb.db.doc(`event/${this.event.id}`).update({
          qnaEnabled: val,
          updatedAt: fb.timestamp(),
        });
        // değişikliklerin ekrana yansıması için event'i çağır
        // await this.getEvent({ id: this.event.id });

        this.$notify({
          title: 'Success',
          message: 'QnA Moderation Settings Changed Successfully',
          type: 'success',
        });

        this.pageIndex++;
      } catch (error) {
        console.log(error);

        this.$notify.error({
          title: 'Error',
          message: 'An Error Occurred While Changing QnA Settings',
        });
      }
    },
    getInterfaceLanguageLabel(val) {
      let lang = null;

      this.languages.forEach((language) => {
        if (language.value === val) {
          lang = language.label;
        }
      });

      return lang || '';
    },
    redirectToQnaModeration() {
      window.location.href = `https://qna.livecasthq.com/${this.event.id}`;
    },
    async redirectToEvent() {
      try {
        // current user'ın id token'ını verify etmek için al ve backend'e yolla
        // backend'den idtoken ve eventId'den gelen bilgilere göre token al
        // bu token'la landingUrl'e yönlendir
        const idToken = await fb.auth.currentUser.getIdToken(true);
        const {
          data: { token },
        } = await axios({
          method: 'post',
          url: `${process.env.VUE_APP_BASE_URL}/mod-token`,
          data: { idToken, eventId: this.event.id },
        });

        window.location.href = `${this.landingUrl}/?t=${token}`;
      } catch (error) {
        console.log(error);
      }
    },
    async start() {
      try {
        this.$confirm('Are You Sure?', 'Start Session', {
          confirmButtonText: 'Yes',
          cancelButtonText: 'No',
          type: 'warning',
        })
          .then(async () => {
            // session başlat
            const statusResponse = await this.startSession();

            this.started = !!statusResponse;
            this.pageIndex++;
          })
          .catch(() => {});
      } catch (error) {
        console.log(error);
      }
    },
    async stop() {
      try {
        this.$confirm('Are You Sure?', 'Stop Session', {
          confirmButtonText: 'Yes',
          cancelButtonText: 'No',
          type: 'warning',
        })
          .then(async () => {
            // session'ı bitir
            const statusResponse = await this.stopSession();

            this.started = !statusResponse;
            this.pageIndex++;
          })
          .catch(() => {});
      } catch (error) {
        console.log(error);
      }
    },
    async end() {
      try {
        this.$confirm('Are You Sure?', 'End Event', {
          confirmButtonText: 'Yes',
          cancelButtonText: 'No',
          type: 'warning',
        })
          .then(async () => {
            // event'i sonlandır
            await this.endEvent();

            this.started = false;
            this.pageIndex++;
          })
          .catch(() => {});
      } catch (error) {
        console.log(error);
      }
    },
    getConnectionCount(event) {
      if (event.currentSession) {
        const onlineRef = fb
          .database()
          .ref(`event/${event._id}/${event.currentSession}`)
          .orderByChild('status')
          .equalTo('playing');

        onlineRef.on('value', (snapshot) => {
          this.connections = snapshot.numChildren();
        });
      }
    },
  },
  data() {
    return {
      form: {},
      languages: [
        { value: 'en', label: 'English' },
        { value: 'de', label: 'Deutsche' },
        { value: 'tr', label: 'Turkish' },
        { value: 'es', label: 'Spanish' },
        { value: 'ro', label: 'Romanian' },
      ],
      dialogFormVisible: false,
      formLabelWidth: '200px',
      moderationEnabled: false,
      pageIndex: 0,
      started: false,
      totalRegistrations: 0,
      qnaEnabled: true,
      connections: 0,
    };
  },
  async created() {
    this.form = {
      ...this.event,
      startAt: moment(this.event.startAt.toDate()),
      endAt: moment(this.event.endAt.toDate()),
    };
    // eğer event'in status'ü live ise başlamış olarak göster
    this.started = this.event.status === 'live';

    const event = (await fb.db.doc(`event/${this.event.id}`).get()).data();

    // eğer event'te qna enabled edilmişse ekrana yansıt
    this.qnaEnabled = event.qnaEnabled;

    const qnaSettingsRef = await fb.db
      .doc(`event/${this.event.id}/settings/qnaSettings`)
      .get();

    // eğer qna ayarları varsa, qnamoderation değerini yansıt
    if (qnaSettingsRef.exists) {
      const qnaSettings = qnaSettingsRef.data();

      this.moderationEnabled = qnaSettings.qnaModeration;
    }

    // registration sayısını al
    this.clearRegistrationState();
    await this.findRegistrationsAction({
      query: [],
      order: { field: 'createdAt', desc: true },
    })
      .then((items) => {
        this.totalRegistrations = items.length;
        console.log(items);
      })
      .catch((error) => {
        this.$alert(error.message, 'Error!', {
          confirmButtonText: 'OK',
          type: 'error',
        });
      });

    // toplam connection sayısını al
    this.getConnectionCount(event);
    // toplam attendee sayısını al
    await this.getAttendeeCount();
    // event'e ait landingUrl'i al
    await this.getLandingUrl();
  },
};
</script>

<style lang="scss">
.container-fluid {
  width: 100%;
}
.settings-list-item {
  display: flex;
  justify-content: space-between;
}
</style>
