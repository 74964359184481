<template>
  <BaseLayout>
    <div class="account">
      <router-view />
    </div>
  </BaseLayout>
</template>

<script>
import BaseLayout from '@/components/layout/BaseLayout.vue';

export default {
  components: {
    BaseLayout,
  },
};
</script>

<style>
</style>
