<template>
  <div style="width: 50%; text-align: center; margin: 0 auto; padding: 2rem;">
    <h3>User</h3>
    <el-form
      :model="form"
      label-position="left"
      :label-width="formLabelWidth"
      :status-icon="true"
      :inline-message="true"
      ref="eventForm"
      style="margin-top: 2rem;"
    >
      <el-form-item label="Full Name" prop="name">
        <el-input v-model="form.name" autocomplete="off"></el-input>
      </el-form-item>

      <el-form-item label="Email" prop="email">
        <el-input
          type="email"
          v-model="form.email"
          autocomplete="off"
        ></el-input>
      </el-form-item>

      <el-form-item label="Role" prop="role">
        <el-select v-model="form.role" placeholder="Select">
          <el-option label="Admin" value="admin" />
          <el-option label="User" value="user" />
        </el-select>
      </el-form-item>

      <el-form-item label="Password" prop="password">
        <el-input
          type="password"
          v-model="form.password"
          autocomplete="off"
        ></el-input>
      </el-form-item>
    </el-form>

    <span slot="footer" class="dialog-footer" style="float: right;">
      <el-button type="primary" @click="saveUser()">Save User</el-button>
    </span>
  </div>
</template>

<script>
/* eslint-disable consistent-return */

import { mapActions } from 'vuex';

export default {
  data() {
    return { form: {}, formLabelWidth: '200px' };
  },
  methods: {
    ...mapActions({
      createUser: 'auth/createUser',
    }),
    async saveUser() {
      try {
        // eldeki formla yeni firebase kullanıcısı oluştur ve firestore'a kaydet
        await this.createUser(this.form);

        // root'a yönlendir
        this.$router.push('/');
      } catch (error) {
        console.log(error);

        return this.$notify.error({
          title: 'Error',
          message: 'An Error Occurred While Saving the User. Please Try Again.',
        });
      }
    },
  },
};
</script>

<style></style>
