<template>
  <el-container direction="vertical" v-if="!loading">
    <div class="report-header">
      <p>Total Registrars: {{ totalRegistrations }}</p>
      <el-button
        type="success"
        style="margin-bottom: 1rem; width: 25%"
        v-on:click="downloadCsv()"
        >Export</el-button
      >
    </div>
    <el-table
      ref="regListTable"
      :data="regList"
      :border="true"
      size="small"
      v-loading="loading"
    >
      <el-table-column
        v-for="formItem in formFields"
        :key="formItem._id"
        :prop="'info.' + getKebab(formItem.name)"
        :label="getStartCase(formItem.name)"
        width="220"
        :formatter="
          (row, column, cellValue, index) =>
            cellValue ? cellValue.toString() : ''
        "
      >
      </el-table-column>

      <el-table-column prop="device.browser" label="Browser" width="100">
      </el-table-column>
      <el-table-column prop="device.city" label="Ip City" width="250">
      </el-table-column>
      <el-table-column prop="device.country" label="Ip Country" width="250">
      </el-table-column>

      <el-table-column prop="device.os" label="Os" width="120">
      </el-table-column>
      <el-table-column prop="device.type" label="Type" width="150">
      </el-table-column>
      <el-table-column prop="device.ip" label="Ip" width="120">
      </el-table-column>
      <el-table-column label="Joined At" width="120">
        <template slot-scope="scope">
          {{ scope.row.createdAt | moment('MMM DD, kk:mm') }}
        </template>
      </el-table-column>
    </el-table>
  </el-container>
</template>

<script>
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unused-vars */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable operator-linebreak */

import { mapGetters, mapActions } from 'vuex';
import _ from 'lodash';

export default {
  data() {
    return {
      onlineList: [],
      loading: false,
      totalRegistrations: 0,
      regformFields: [],
    };
  },
  computed: {
    ...mapGetters({
      currentEvent: 'event/current',
      regList: 'registration/list',
    }),
    formFields() {
      // görüntülenmesi gereken ve html content olmayan form field'larını seç
      return _.filter(
        this.regformFields,
        (value, key) => value.is_shown && value.type !== 'html_content',
      );
    },
  },
  methods: {
    ...mapActions({
      findRegistrationsAction: 'registration/find',
      clearState: 'registration/clearItems',
      getFields: 'regForm/find',
    }),
    getKebab(v) {
      return _.kebabCase(v);
    },
    getStartCase(v) {
      return _.startCase(v);
    },
    downloadCsv() {
      // export edilecek field'ların listesi
      const fields = this.getSortedFields();
      // düzenlenmiş field bilgileri
      const registrations = this.sanitizeData();
      // csv dosyasının ilk satırını field isimleriyle oluşturma
      let csv = _.join(fields, ',');

      csv += '\n';

      // her katılımcıyı yeni satıra ekleme
      registrations.forEach((row) => {
        csv += _.values(row).join(',');
        csv += '\n';
      });

      const hiddenElement = document.createElement('a');

      hiddenElement.href = `data:text/csv;charset=utf-8,${encodeURI(csv)}`;
      hiddenElement.target = '_blank';
      hiddenElement.download = 'registrations.csv';
      hiddenElement.click();
    },
    sanitizeData() {
      const unsanitizedRegistrations = [];

      this.regList.forEach((registration) => {
        const { info, device, ...restOf } = registration;
        const tempRegistration = {};

        // info ya da device içinde olmayan field'ları temp katılımcı kaydına ekleme
        // field key'ler start case olacak şekilde
        Object.keys(restOf).forEach((key) => {
          tempRegistration[this.getStartCase(key)] = restOf[key];
        });

        // aynı şeyi device objesi içindeki field'lar için yap
        if (device) {
          Object.keys(device).forEach((key) => {
            if (key !== '_id') {
              if (key === 'city' || key === 'country') {
                tempRegistration[`Ip ${this.getStartCase(key)}`] = device[key];
              } else {
                tempRegistration[this.getStartCase(key)] = device[key];
              }
            }
          });
        }

        // aynı şeyi info objesi içindeki field'lar için yap
        if (info) {
          Object.keys(info).forEach((key) => {
            tempRegistration[this.getStartCase(key)] = info[key];
          });
        }

        unsanitizedRegistrations.push(tempRegistration);
      });

      const fields = this.getSortedFields();
      const registrations = [];

      // katılımcı listesini alfabetize edilmiş field listesine göre düzenle
      unsanitizedRegistrations.forEach((registration) => {
        const tempRegistration = {};

        fields.forEach((field) => {
          Object.assign(tempRegistration, {
            [field]: registration[field] ? registration[field] : '',
          });
        });

        registrations.push(tempRegistration);
      });

      return registrations;
    },
    getSortedFields() {
      // regform'dan gelen görüntülenmesi gereken ve html content olmayan field'ları seç
      const regFields = _.filter(
        this.regformFields,
        (value, key) => value.is_shown && value.type !== 'html_content',
      );
      let fields = [];

      regFields.forEach((field) => {
        fields.push(field.name);
      });

      // regform dışında rapora yansıtılması gereken field'lar
      const otherFields = [
        'browser',
        'type',
        'os',
        'ip',
        'ipCity',
        'ipCountry',
        'country',
        'createdAt',
      ];

      fields = fields.concat(otherFields);

      // isimleri alfabetize ve start case görünecek şekilde düzenle ve döndür
      return fields.map((field) => {
        const tempField = this.getKebab(field);

        return this.getStartCase(tempField);
      });
    },
  },
  async mounted() {
    this.loading = true;

    this.clearState();
    await this.findRegistrationsAction({
      query: [],
      order: { field: 'createdAt', desc: true },
    })
      .then((items) => {
        this.totalRegistrations = items.length;
        console.log(items);
      })
      .catch((error) => {
        this.$alert(error.message, 'Error!', {
          confirmButtonText: 'OK',
          type: 'error',
        });
      });

    console.log(this.currentEvent);
    this.regformFields = await this.getFields({
      path: '',
      subscribe: false,
      order: { field: 'order' },
    });
    console.log(this.regformFields);

    this.loading = false;
  },
};
</script>

<style lang="scss">
.el-table {
  flex-grow: 100 !important;
  align-self: stretch;
}
.report-header {
  display: flex;
  justify-content: space-between;
}
</style>
