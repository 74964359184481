<template>
  <div
    :key="pageIndex"
    style="width: 50%; text-align: center; margin: 0 auto; padding: 2rem;"
  >
    <el-row
      v-loading="accountLoading"
      element-loading-spinner="el-icon-loading"
    >
      <el-row>
        <h3>Account</h3>
      </el-row>
      <el-row :gutter="20" type="flex" justify="space-between">
        <el-col :span="24">
          <el-form
            :model="form"
            label-position="left"
            :label-width="formLabelWidth"
            :status-icon="true"
            :inline-message="true"
            ref="eventForm"
          >
            <el-form-item label="Organization Name" prop="name">
              <el-input v-model="form.name" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="Landing Url" prop="landingUrl">
              <el-input
                v-model="form.landingUrl"
                autocomplete="off"
              ></el-input> </el-form-item
            ><el-form-item label="Webinar Capacity" prop="webinarCapacity">
              <el-select v-model="form.webinarCapacity" placeholder="Select">
                <el-option
                  v-for="item in capacityOptions"
                  :key="item.label"
                  :label="item.label"
                  :value="item.presenter"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
      <el-row>
        <el-button type="primary" @click="save()" style="float: right;"
          >Save</el-button
        >
      </el-row>
    </el-row>

    <el-row>
      <el-row>
        <h3>Users</h3>
      </el-row>
      <el-row s :gutter="20" type="flex" justify="space-between">
        <el-col :span="12">
          <el-input
            type="email"
            label="Email"
            placeholder="Email"
            v-model="email"
            autocomplete="off"
          ></el-input>
        </el-col>
        <el-col :span="8">
          <el-select v-model="role" placeholder="Select">
            <el-option label="Manager" value="manager" />
            <el-option label="Moderator" value="moderator" />
          </el-select>
        </el-col>
        <el-col :span="4">
          <el-button type="primary" @click="addNewUser">Add</el-button>
        </el-col>
      </el-row>
    </el-row>

    <el-row>
      <el-table
        :data="users"
        :border="true"
        style="margin-top: 2rem;"
        v-loading="usersLoading"
      >
        <el-table-column prop="name" label="Name"> </el-table-column>
        <el-table-column prop="role" label="Role"> </el-table-column>
        <el-table-column label="Action">
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="danger"
              @click="handleDelete(scope.$index, scope.row)"
              >Delete</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </el-row>
  </div>
</template>

<script>
/* eslint-disable object-curly-newline */
/* eslint-disable no-plusplus */
/* eslint-disable consistent-return */
/* eslint-disable func-names */

import { mapActions, mapGetters } from 'vuex';

export default {
  async created() {
    try {
      this.accountLoading = true;
      this.usersLoading = true;

      // url parametresindeki id değerine ait olan account'u çek
      await this.getAccount(this.$route.params.id);

      this.form = {
        ...this.account,
        webinarCapacity: this.account.webinarCapacity
          ? this.account.webinarCapacity.presenter
          : 2,
      };
      this.accountLoading = false;

      // bu account'a ait kullanıcıları çek
      await this.fetchUsers(this.$route.params.id);

      this.usersLoading = false;
      this.pageIndex++;
    } catch (error) {
      console.log(error);
    }
  },
  data() {
    return {
      pageIndex: 0,
      form: {},
      formLabelWidth: '200px',
      email: '',
      role: 'moderator',
      accountLoading: false,
      usersLoading: false,
      capacityOptions: [
        {
          label: '50 Presenters 10 Attendees',
          presenter: 50,
          attendee: 10,
        },
        {
          label: '24 Presenters 100 Attendees',
          presenter: 24,
          attendee: 100,
        },
        {
          label: '10 Presenters 250 Attendees',
          presenter: 10,
          attendee: 250,
        },
        {
          label: '5 Presenters 500 Attendees',
          presenter: 5,
          attendee: 500,
        },
        {
          label: '3 Presenters 750 Attendees',
          presenter: 3,
          attendee: 750,
        },
        {
          label: '2 Presenters 1000 Attendees',
          presenter: 2,
          attendee: 1000,
        },
      ],
    };
  },
  methods: {
    ...mapActions({
      getAccount: 'account/init',
      updateAccount: 'account/updateAccount',
      addNewAccountUser: 'account/addNewUser',
      fetchUsers: 'account/fetchUsers',
      deleteUser: 'account/deleteUser',
    }),
    handleWebinarCapacityForm() {
      const element = (() => {
        for (let i = 0; i < this.capacityOptions.length; i++) {
          if (this.capacityOptions[i].presenter === this.form.webinarCapacity) {
            return {
              presenter: this.capacityOptions[i].presenter,
              attendee: this.capacityOptions[i].attendee,
            };
          }
        }
      })();

      return element || { presenter: 2, attendee: 1000 };
    },
    async save() {
      try {
        this.accountLoading = true;

        const webinarCapacity = this.handleWebinarCapacityForm();

        // account'u güncelle
        await this.updateAccount({ ...this.form, webinarCapacity });
        this.$notify({
          title: 'Success',
          message: 'Account Edited Successfully',
          type: 'success',
        });

        this.accountLoading = false;
      } catch (error) {
        console.log(error);

        this.accountLoading = false;

        this.$notify.error({
          title: 'Error',
          message: error.message
            ? error.message
            : 'An Error Occurred While Editing the Account. Please Try Again.',
        });
      }
    },
    async addNewUser() {
      try {
        // email string'indeki boşlukları temizle
        const email = this.email.replace(/\s/g, '');

        // eğer email boş string değilse
        if (email !== '') {
          this.usersLoading = true;

          // yeni account user'ı oluştur
          await this.addNewAccountUser({
            email,
            role: this.role,
            accountId: this.account.id,
          });

          this.$notify({
            title: 'Success',
            message: 'User Added Successfully',
            type: 'success',
          });
          this.usersLoading = false;
          this.pageIndex++;
        }
      } catch (error) {
        console.log(error);

        this.usersLoading = false;

        return this.$notify.error({
          title: 'Error',
          message: error.message
            ? error.message
            : 'An Error Occurred While Adding the User. Please Try Again.',
        });
      }
    },
    async handleDelete(index) {
      try {
        this.usersLoading = true;

        await this.deleteUser({ index, accountId: this.account.id });
        this.$notify({
          title: 'Success',
          message: 'User Deleted Successfully',
          type: 'success',
        });

        this.usersLoading = false;
        this.pageIndex++;
      } catch (error) {
        console.log(error);

        this.usersLoading = false;

        return this.$notify.error({
          title: 'Error',
          message: error.message
            ? error.message
            : 'An Error Occurred While Deleting the User. Please Try Again.',
        });
      }
    },
  },
  computed: {
    ...mapGetters({
      users: 'account/users',
      account: 'account/current',
    }),
  },
};
</script>

<style></style>
